import { createTheme } from '@mui/material';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
  interface Theme {
    space: {
      xxs: string;
      xs: string;
      s: string;
      m: string;
      l: string;
      xl: string;
      xxl: string;
    };
    workout: {
      color: {
        emom: string;
        amrap: string;
        tabata: string;
        fortime: string;
        hyrox: string;
      };
    };
  }
  interface ThemeOptions {
    space: {
      xxs: string;
      xs: string;
      s: string;
      m: string;
      l: string;
      xl: string;
      xxl: string;
    };
    workout: {
      color: {
        emom: string;
        amrap: string;
        tabata: string;
        fortime: string;
        hyrox: string;
      };
    };
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: ['Anton', 'Arial'].join(','),
    body1: {
      fontFamily: ['Anton'].join(','),
    },
    body2: {
      fontFamily: ['Arial'].join(','),
    },
  },
  palette: {
    primary: {
      main: '#662289',
    },
    secondary: {
      main: '#ee2a7b',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#000000',
      secondary: '#000000',
    },
  },
  space: {
    xxs: '4px',
    xs: '8px',
    s: '16px',
    m: '24px',
    l: '32px',
    xl: '40px',
    xxl: '48px',
  },
  workout: {
    color: {
      emom: '#B0265F',
      amrap: '#dd9d02',
      tabata: '#5f006c',
      fortime: '#203f52',
      hyrox: '#000000',
    },
  },
});
