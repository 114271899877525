import { useApproveWorkout } from '@hooks/useApproveWorkout';
import { useLogin } from '@hooks/useLogin';
import { useQueryWorkout } from '@hooks/useQueryWorkout';
import { AddTaskRounded as ApproveWorkoutIcon } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { http404 as NotFoundImage } from '../assets';
import { InfoMessage } from '../components/InfoMessage';
import { Workout } from '../components/Workout';
import { Loading, MainContent } from '../layout/components';

const TESTIDS = {
  wrapper: 'workout-wrapper',
  workout: 'workout-content',
  tryAgain: 'try-again-content',
  loading: 'loading',
  approveBtn: 'approve-button',
};

export const WorkoutPreviewPage = () => {
  const theme = useTheme();
  const { googleToken, isAdmin } = useLogin();

  const { id } = useParams();

  const {
    workout,
    isLoading,
    error,
    refetch: refreshWorkout,
  } = useQueryWorkout(id, googleToken);

  const { isSuccess: isApproveSuccess, approve } =
    useApproveWorkout(googleToken);

  useEffect(() => {
    if (isApproveSuccess) {
      refreshWorkout();
    }
  }, [isApproveSuccess]);

  if (isLoading) {
    return (
      <MainContent>
        <Box data-testid={TESTIDS.loading}>
          <Loading size={200} color={theme.palette.secondary.main} />
        </Box>
      </MainContent>
    );
  }

  return (
    <MainContent>
      <Grid
        data-testid={TESTIDS.wrapper}
        spacing={theme.space.l}
        container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {workout && (
          <Grid item data-testid={TESTIDS.workout}>
            <Workout workout={workout} />
          </Grid>
        )}
        {error || !id ? (
          <Grid item data-testid={TESTIDS.tryAgain}>
            <InfoMessage
              image={NotFoundImage}
              message="We could not find any workout for you, please try again later!"
            />
          </Grid>
        ) : null}
        {isAdmin && id && !workout?.metadata.isApproved ? (
          <Grid item>
            <Typography component="p">
              Do you want to approve this workout:{' '}
              <IconButton
                sx={{ p: 0 }}
                aria-label="approve"
                data-testid={TESTIDS.approveBtn}
                onClick={() => approve(id)}
              >
                <ApproveWorkoutIcon fontSize="inherit" color="warning" />
              </IconButton>
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </MainContent>
  );
};

WorkoutPreviewPage.TESTIDS = TESTIDS;
