import { ApiContext } from '../package/api/context';
import { WorkoutElem } from '../package/api/src/thunderwod';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

export const useQueryWorkoutGenerator = (
  workoutType: string,
  workoutEquipment?: string[],
) => {
  const { endpoints } = useContext(ApiContext);

  const workoutTypeApi =
    workoutType.toUpperCase() !== 'HYROX' ? workoutType : undefined;
  const isWorkoutHyrox = workoutType.toUpperCase() === 'HYROX';

  const { data, isLoading, error, refetch } = useQuery(
    ['workoutType', workoutType],
    () =>
      endpoints.thunderwod.generateRandomWorkout(
        undefined,
        workoutTypeApi,
        undefined,
        isWorkoutHyrox,
        workoutEquipment,
      ),
    {
      enabled: !!workoutType,
      cacheTime: 0,
    },
  );

  const [workout, setWorkout] = useState<WorkoutElem | undefined>();

  useEffect(() => {
    if (!error) {
      //FIXME: Find a better way to get the first workout
      setWorkout(data?.data.workouts.values().next().value);
    }
  }, [data]);

  return { workout: workout, isLoading, error, refetch };
};
