import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { TimeUtils } from '../../../../util/TimeUtils';
import { WorkoutScale } from 'src/types';

type Props = {
  name: string;
  total: number;
  scale: WorkoutScale;
};

const TESTIDS = {
  wrapper: 'wrapper-exercise-aerobic',
};

export const ExerciseAerobic = ({ name, total, scale }: Props) => {
  const buildText = useMemo(() => {
    switch (scale) {
      case 'Reps':
        return `${total} ${name}`;
      case 'Meters':
      case 'Cal':
        return `${total} ${scale} ${name}`;
      case 'Minutes':
        return `${TimeUtils.convertSecondsToMinutes(total)} ${name}`;
    }
  }, [name, scale, total]);

  return (
    <Typography data-testid={TESTIDS.wrapper} component="div" variant="body2">
      {buildText}
    </Typography>
  );
};

ExerciseAerobic.TESTIDS = TESTIDS;
