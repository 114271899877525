/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ApplicationContext
 */
export interface ApplicationContext {
  /**
   *
   * @type {ApplicationContext}
   * @memberof ApplicationContext
   */
  parent?: ApplicationContext;
  /**
   *
   * @type {string}
   * @memberof ApplicationContext
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationContext
   */
  displayName?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationContext
   */
  startupDate?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationContext
   */
  applicationName?: string;
  /**
   *
   * @type {object}
   * @memberof ApplicationContext
   */
  autowireCapableBeanFactory?: object;
  /**
   *
   * @type {Environment}
   * @memberof ApplicationContext
   */
  environment?: Environment;
  /**
   *
   * @type {number}
   * @memberof ApplicationContext
   */
  beanDefinitionCount?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationContext
   */
  beanDefinitionNames?: Array<string>;
  /**
   *
   * @type {object}
   * @memberof ApplicationContext
   */
  parentBeanFactory?: object;
  /**
   *
   * @type {ApplicationContextClassLoader}
   * @memberof ApplicationContext
   */
  classLoader?: ApplicationContextClassLoader;
}
/**
 *
 * @export
 * @interface ApplicationContextClassLoader
 */
export interface ApplicationContextClassLoader {
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoader
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoader
   */
  registeredAsParallelCapable?: boolean;
  /**
   *
   * @type {ApplicationContextClassLoaderParent}
   * @memberof ApplicationContextClassLoader
   */
  parent?: ApplicationContextClassLoaderParent;
  /**
   *
   * @type {ApplicationContextClassLoaderParentUnnamedModule}
   * @memberof ApplicationContextClassLoader
   */
  unnamedModule?: ApplicationContextClassLoaderParentUnnamedModule;
  /**
   *
   * @type {Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner>}
   * @memberof ApplicationContextClassLoader
   */
  definedPackages?: Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoader
   */
  defaultAssertionStatus?: boolean;
}
/**
 *
 * @export
 * @interface ApplicationContextClassLoaderParent
 */
export interface ApplicationContextClassLoaderParent {
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParent
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParent
   */
  registeredAsParallelCapable?: boolean;
  /**
   *
   * @type {ApplicationContextClassLoaderParentUnnamedModule}
   * @memberof ApplicationContextClassLoaderParent
   */
  unnamedModule?: ApplicationContextClassLoaderParentUnnamedModule;
  /**
   *
   * @type {Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner>}
   * @memberof ApplicationContextClassLoaderParent
   */
  definedPackages?: Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParent
   */
  defaultAssertionStatus?: boolean;
}
/**
 *
 * @export
 * @interface ApplicationContextClassLoaderParentUnnamedModule
 */
export interface ApplicationContextClassLoaderParentUnnamedModule {
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  name?: string;
  /**
   *
   * @type {ApplicationContextClassLoaderParentUnnamedModuleClassLoader}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  classLoader?: ApplicationContextClassLoaderParentUnnamedModuleClassLoader;
  /**
   *
   * @type {ApplicationContextClassLoaderParentUnnamedModuleDescriptor}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  descriptor?: ApplicationContextClassLoaderParentUnnamedModuleDescriptor;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  named?: boolean;
  /**
   *
   * @type {Array<object>}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  annotations?: Array<object>;
  /**
   *
   * @type {Array<object>}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  declaredAnnotations?: Array<object>;
  /**
   *
   * @type {Set<string>}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  packages?: Set<string>;
  /**
   *
   * @type {object}
   * @memberof ApplicationContextClassLoaderParentUnnamedModule
   */
  layer?: object;
}
/**
 *
 * @export
 * @interface ApplicationContextClassLoaderParentUnnamedModuleClassLoader
 */
export interface ApplicationContextClassLoaderParentUnnamedModuleClassLoader {
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoader
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoader
   */
  registeredAsParallelCapable?: boolean;
  /**
   *
   * @type {Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner>}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoader
   */
  definedPackages?: Array<ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoader
   */
  defaultAssertionStatus?: boolean;
}
/**
 *
 * @export
 * @interface ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
 */
export interface ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner {
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  name?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  annotations?: Array<object>;
  /**
   *
   * @type {Array<object>}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  declaredAnnotations?: Array<object>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  sealed?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  specificationTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  specificationVersion?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  specificationVendor?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  implementationTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  implementationVersion?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleClassLoaderDefinedPackagesInner
   */
  implementationVendor?: string;
}
/**
 *
 * @export
 * @interface ApplicationContextClassLoaderParentUnnamedModuleDescriptor
 */
export interface ApplicationContextClassLoaderParentUnnamedModuleDescriptor {
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleDescriptor
   */
  open?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationContextClassLoaderParentUnnamedModuleDescriptor
   */
  automatic?: boolean;
}
/**
 *
 * @export
 * @interface ContentsRequest
 */
export interface ContentsRequest {
  /**
   *
   * @type {string}
   * @memberof ContentsRequest
   */
  role: string;
  /**
   *
   * @type {string}
   * @memberof ContentsRequest
   */
  text: string;
}
/**
 *
 * @export
 * @interface Environment
 */
export interface Environment {
  /**
   *
   * @type {Array<string>}
   * @memberof Environment
   */
  activeProfiles?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof Environment
   */
  defaultProfiles?: Array<string>;
}
/**
 *
 * @export
 * @interface EquipmentElem
 */
export interface EquipmentElem {
  /**
   *
   * @type {string}
   * @memberof EquipmentElem
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof EquipmentElem
   */
  value: string;
}
/**
 *
 * @export
 * @interface FilterRegistration
 */
export interface FilterRegistration {
  /**
   *
   * @type {Array<string>}
   * @memberof FilterRegistration
   */
  servletNameMappings?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof FilterRegistration
   */
  urlPatternMappings?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof FilterRegistration
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof FilterRegistration
   */
  className?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof FilterRegistration
   */
  initParameters?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface GeneralResponse
 */
export interface GeneralResponse {
  /**
   *
   * @type {string}
   * @memberof GeneralResponse
   */
  httpStatus: GeneralResponseHttpStatusEnum;
  /**
   *
   * @type {string}
   * @memberof GeneralResponse
   */
  timestamp: string;
  /**
   *
   * @type {string}
   * @memberof GeneralResponse
   */
  message?: string;
}

export const GeneralResponseHttpStatusEnum = {
  _100Continue: '100 CONTINUE',
  _101SwitchingProtocols: '101 SWITCHING_PROTOCOLS',
  _102Processing: '102 PROCESSING',
  _103EarlyHints: '103 EARLY_HINTS',
  _103Checkpoint: '103 CHECKPOINT',
  _200Ok: '200 OK',
  _201Created: '201 CREATED',
  _202Accepted: '202 ACCEPTED',
  _203NonAuthoritativeInformation: '203 NON_AUTHORITATIVE_INFORMATION',
  _204NoContent: '204 NO_CONTENT',
  _205ResetContent: '205 RESET_CONTENT',
  _206PartialContent: '206 PARTIAL_CONTENT',
  _207MultiStatus: '207 MULTI_STATUS',
  _208AlreadyReported: '208 ALREADY_REPORTED',
  _226ImUsed: '226 IM_USED',
  _300MultipleChoices: '300 MULTIPLE_CHOICES',
  _301MovedPermanently: '301 MOVED_PERMANENTLY',
  _302Found: '302 FOUND',
  _302MovedTemporarily: '302 MOVED_TEMPORARILY',
  _303SeeOther: '303 SEE_OTHER',
  _304NotModified: '304 NOT_MODIFIED',
  _305UseProxy: '305 USE_PROXY',
  _307TemporaryRedirect: '307 TEMPORARY_REDIRECT',
  _308PermanentRedirect: '308 PERMANENT_REDIRECT',
  _400BadRequest: '400 BAD_REQUEST',
  _401Unauthorized: '401 UNAUTHORIZED',
  _402PaymentRequired: '402 PAYMENT_REQUIRED',
  _403Forbidden: '403 FORBIDDEN',
  _404NotFound: '404 NOT_FOUND',
  _405MethodNotAllowed: '405 METHOD_NOT_ALLOWED',
  _406NotAcceptable: '406 NOT_ACCEPTABLE',
  _407ProxyAuthenticationRequired: '407 PROXY_AUTHENTICATION_REQUIRED',
  _408RequestTimeout: '408 REQUEST_TIMEOUT',
  _409Conflict: '409 CONFLICT',
  _410Gone: '410 GONE',
  _411LengthRequired: '411 LENGTH_REQUIRED',
  _412PreconditionFailed: '412 PRECONDITION_FAILED',
  _413PayloadTooLarge: '413 PAYLOAD_TOO_LARGE',
  _413RequestEntityTooLarge: '413 REQUEST_ENTITY_TOO_LARGE',
  _414UriTooLong: '414 URI_TOO_LONG',
  _414RequestUriTooLong: '414 REQUEST_URI_TOO_LONG',
  _415UnsupportedMediaType: '415 UNSUPPORTED_MEDIA_TYPE',
  _416RequestedRangeNotSatisfiable: '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  _417ExpectationFailed: '417 EXPECTATION_FAILED',
  _418IAmATeapot: '418 I_AM_A_TEAPOT',
  _419InsufficientSpaceOnResource: '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  _420MethodFailure: '420 METHOD_FAILURE',
  _421DestinationLocked: '421 DESTINATION_LOCKED',
  _422UnprocessableEntity: '422 UNPROCESSABLE_ENTITY',
  _423Locked: '423 LOCKED',
  _424FailedDependency: '424 FAILED_DEPENDENCY',
  _425TooEarly: '425 TOO_EARLY',
  _426UpgradeRequired: '426 UPGRADE_REQUIRED',
  _428PreconditionRequired: '428 PRECONDITION_REQUIRED',
  _429TooManyRequests: '429 TOO_MANY_REQUESTS',
  _431RequestHeaderFieldsTooLarge: '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  _451UnavailableForLegalReasons: '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  _500InternalServerError: '500 INTERNAL_SERVER_ERROR',
  _501NotImplemented: '501 NOT_IMPLEMENTED',
  _502BadGateway: '502 BAD_GATEWAY',
  _503ServiceUnavailable: '503 SERVICE_UNAVAILABLE',
  _504GatewayTimeout: '504 GATEWAY_TIMEOUT',
  _505HttpVersionNotSupported: '505 HTTP_VERSION_NOT_SUPPORTED',
  _506VariantAlsoNegotiates: '506 VARIANT_ALSO_NEGOTIATES',
  _507InsufficientStorage: '507 INSUFFICIENT_STORAGE',
  _508LoopDetected: '508 LOOP_DETECTED',
  _509BandwidthLimitExceeded: '509 BANDWIDTH_LIMIT_EXCEEDED',
  _510NotExtended: '510 NOT_EXTENDED',
  _511NetworkAuthenticationRequired: '511 NETWORK_AUTHENTICATION_REQUIRED',
} as const;

export type GeneralResponseHttpStatusEnum =
  (typeof GeneralResponseHttpStatusEnum)[keyof typeof GeneralResponseHttpStatusEnum];

/**
 *
 * @export
 * @interface HttpStatusCode
 */
export interface HttpStatusCode {
  /**
   *
   * @type {boolean}
   * @memberof HttpStatusCode
   */
  error?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HttpStatusCode
   */
  is4xxClientError?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HttpStatusCode
   */
  is5xxServerError?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HttpStatusCode
   */
  is1xxInformational?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HttpStatusCode
   */
  is2xxSuccessful?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HttpStatusCode
   */
  is3xxRedirection?: boolean;
}
/**
 *
 * @export
 * @interface JspConfigDescriptor
 */
export interface JspConfigDescriptor {
  /**
   *
   * @type {Array<JspPropertyGroupDescriptor>}
   * @memberof JspConfigDescriptor
   */
  jspPropertyGroups?: Array<JspPropertyGroupDescriptor>;
  /**
   *
   * @type {Array<TaglibDescriptor>}
   * @memberof JspConfigDescriptor
   */
  taglibs?: Array<TaglibDescriptor>;
}
/**
 *
 * @export
 * @interface JspPropertyGroupDescriptor
 */
export interface JspPropertyGroupDescriptor {
  /**
   *
   * @type {string}
   * @memberof JspPropertyGroupDescriptor
   */
  buffer?: string;
  /**
   *
   * @type {string}
   * @memberof JspPropertyGroupDescriptor
   */
  elIgnored?: string;
  /**
   *
   * @type {string}
   * @memberof JspPropertyGroupDescriptor
   */
  errorOnELNotFound?: string;
  /**
   *
   * @type {string}
   * @memberof JspPropertyGroupDescriptor
   */
  pageEncoding?: string;
  /**
   *
   * @type {string}
   * @memberof JspPropertyGroupDescriptor
   */
  scriptingInvalid?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof JspPropertyGroupDescriptor
   */
  includePreludes?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof JspPropertyGroupDescriptor
   */
  includeCodas?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof JspPropertyGroupDescriptor
   */
  defaultContentType?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof JspPropertyGroupDescriptor
   */
  urlPatterns?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof JspPropertyGroupDescriptor
   */
  deferredSyntaxAllowedAsLiteral?: string;
  /**
   *
   * @type {string}
   * @memberof JspPropertyGroupDescriptor
   */
  trimDirectiveWhitespaces?: string;
  /**
   *
   * @type {string}
   * @memberof JspPropertyGroupDescriptor
   */
  errorOnUndeclaredNamespace?: string;
  /**
   *
   * @type {string}
   * @memberof JspPropertyGroupDescriptor
   */
  isXml?: string;
}
/**
 *
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
  /**
   *
   * @type {string}
   * @memberof LoginResponse
   */
  token?: string;
  /**
   *
   * @type {GeneralResponse}
   * @memberof LoginResponse
   */
  response: GeneralResponse;
}
/**
 *
 * @export
 * @interface Metadata
 */
export interface Metadata {
  /**
   *
   * @type {boolean}
   * @memberof Metadata
   */
  isApproved: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Metadata
   */
  isHyrox: boolean;
  /**
   *
   * @type {string}
   * @memberof Metadata
   */
  createdByInstagram?: string;
}
/**
 *
 * @export
 * @interface MetadataPageable
 */
export interface MetadataPageable {
  /**
   *
   * @type {number}
   * @memberof MetadataPageable
   */
  currentPage: number;
  /**
   *
   * @type {number}
   * @memberof MetadataPageable
   */
  lastPage: number;
  /**
   *
   * @type {number}
   * @memberof MetadataPageable
   */
  sizePerPage: number;
  /**
   *
   * @type {number}
   * @memberof MetadataPageable
   */
  totalOfElem: number;
}
/**
 *
 * @export
 * @interface MovementElem
 */
export interface MovementElem {
  /**
   *
   * @type {string}
   * @memberof MovementElem
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof MovementElem
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MovementElem
   */
  note?: string;
  /**
   *
   * @type {string}
   * @memberof MovementElem
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof MovementElem
   */
  complexity?: string;
  /**
   *
   * @type {string}
   * @memberof MovementElem
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof MovementElem
   */
  equipment?: string;
}
/**
 *
 * @export
 * @interface MovementRequest
 */
export interface MovementRequest {
  /**
   *
   * @type {Array<MovementElem>}
   * @memberof MovementRequest
   */
  movements: Array<MovementElem>;
}
/**
 *
 * @export
 * @interface MovementResponse
 */
export interface MovementResponse {
  /**
   *
   * @type {Array<MovementElem>}
   * @memberof MovementResponse
   */
  movements: Array<MovementElem>;
  /**
   *
   * @type {MetadataPageable}
   * @memberof MovementResponse
   */
  metadata?: MetadataPageable;
  /**
   *
   * @type {GeneralResponse}
   * @memberof MovementResponse
   */
  response: GeneralResponse;
}
/**
 *
 * @export
 * @interface RedirectView
 */
export interface RedirectView {
  /**
   *
   * @type {ApplicationContext}
   * @memberof RedirectView
   */
  applicationContext?: ApplicationContext;
  /**
   *
   * @type {ServletContext}
   * @memberof RedirectView
   */
  servletContext?: ServletContext;
  /**
   *
   * @type {string}
   * @memberof RedirectView
   */
  contentType?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectView
   */
  requestContextAttribute?: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof RedirectView
   */
  staticAttributes?: { [key: string]: object };
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  exposePathVariables?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  exposeContextBeansAsAttributes?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof RedirectView
   */
  exposedContextBeanNames?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof RedirectView
   */
  beanName?: string;
  /**
   *
   * @type {string}
   * @memberof RedirectView
   */
  url?: string;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  contextRelative?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  http10Compatible?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  exposeModelAttributes?: boolean;
  /**
   *
   * @type {string}
   * @memberof RedirectView
   */
  encodingScheme?: string;
  /**
   *
   * @type {HttpStatusCode}
   * @memberof RedirectView
   */
  statusCode?: HttpStatusCode;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  expandUriTemplateVariables?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  propagateQueryParams?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof RedirectView
   */
  hosts?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  propagateQueryProperties?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RedirectView
   */
  redirectView?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof RedirectView
   */
  attributes?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof RedirectView
   */
  attributesCSV?: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof RedirectView
   */
  attributesMap?: { [key: string]: object };
}
/**
 *
 * @export
 * @interface ServletContext
 */
export interface ServletContext {
  /**
   *
   * @type {ApplicationContextClassLoaderParentUnnamedModuleClassLoader}
   * @memberof ServletContext
   */
  classLoader?: ApplicationContextClassLoaderParentUnnamedModuleClassLoader;
  /**
   *
   * @type {number}
   * @memberof ServletContext
   */
  majorVersion?: number;
  /**
   *
   * @type {number}
   * @memberof ServletContext
   */
  minorVersion?: number;
  /**
   *
   * @type {string}
   * @memberof ServletContext
   */
  contextPath?: string;
  /**
   *
   * @type {object}
   * @memberof ServletContext
   */
  attributeNames?: object;
  /**
   *
   * @type {number}
   * @memberof ServletContext
   */
  effectiveMajorVersion?: number;
  /**
   *
   * @type {number}
   * @memberof ServletContext
   */
  effectiveMinorVersion?: number;
  /**
   *
   * @type {string}
   * @memberof ServletContext
   */
  servletContextName?: string;
  /**
   *
   * @type {{ [key: string]: FilterRegistration; }}
   * @memberof ServletContext
   */
  filterRegistrations?: { [key: string]: FilterRegistration };
  /**
   *
   * @type {JspConfigDescriptor}
   * @memberof ServletContext
   */
  jspConfigDescriptor?: JspConfigDescriptor;
  /**
   *
   * @type {number}
   * @memberof ServletContext
   */
  sessionTimeout?: number;
  /**
   *
   * @type {{ [key: string]: ServletRegistration; }}
   * @memberof ServletContext
   */
  servletRegistrations?: { [key: string]: ServletRegistration };
  /**
   *
   * @type {string}
   * @memberof ServletContext
   */
  serverInfo?: string;
  /**
   *
   * @type {Set<string>}
   * @memberof ServletContext
   */
  sessionTrackingModes?: Set<ServletContextSessionTrackingModesEnum>;
  /**
   *
   * @type {SessionCookieConfig}
   * @memberof ServletContext
   */
  sessionCookieConfig?: SessionCookieConfig;
  /**
   *
   * @type {string}
   * @memberof ServletContext
   */
  virtualServerName?: string;
  /**
   *
   * @type {object}
   * @memberof ServletContext
   */
  initParameterNames?: object;
  /**
   *
   * @type {Set<string>}
   * @memberof ServletContext
   */
  defaultSessionTrackingModes?: Set<ServletContextDefaultSessionTrackingModesEnum>;
  /**
   *
   * @type {Set<string>}
   * @memberof ServletContext
   */
  effectiveSessionTrackingModes?: Set<ServletContextEffectiveSessionTrackingModesEnum>;
  /**
   *
   * @type {string}
   * @memberof ServletContext
   */
  requestCharacterEncoding?: string;
  /**
   *
   * @type {string}
   * @memberof ServletContext
   */
  responseCharacterEncoding?: string;
}

export const ServletContextSessionTrackingModesEnum = {
  Cookie: 'COOKIE',
  Url: 'URL',
  Ssl: 'SSL',
} as const;

export type ServletContextSessionTrackingModesEnum =
  (typeof ServletContextSessionTrackingModesEnum)[keyof typeof ServletContextSessionTrackingModesEnum];
export const ServletContextDefaultSessionTrackingModesEnum = {
  Cookie: 'COOKIE',
  Url: 'URL',
  Ssl: 'SSL',
} as const;

export type ServletContextDefaultSessionTrackingModesEnum =
  (typeof ServletContextDefaultSessionTrackingModesEnum)[keyof typeof ServletContextDefaultSessionTrackingModesEnum];
export const ServletContextEffectiveSessionTrackingModesEnum = {
  Cookie: 'COOKIE',
  Url: 'URL',
  Ssl: 'SSL',
} as const;

export type ServletContextEffectiveSessionTrackingModesEnum =
  (typeof ServletContextEffectiveSessionTrackingModesEnum)[keyof typeof ServletContextEffectiveSessionTrackingModesEnum];

/**
 *
 * @export
 * @interface ServletRegistration
 */
export interface ServletRegistration {
  /**
   *
   * @type {Array<string>}
   * @memberof ServletRegistration
   */
  mappings?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ServletRegistration
   */
  runAsRole?: string;
  /**
   *
   * @type {string}
   * @memberof ServletRegistration
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ServletRegistration
   */
  className?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ServletRegistration
   */
  initParameters?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface SessionCookieConfig
 */
export interface SessionCookieConfig {
  /**
   *
   * @type {string}
   * @memberof SessionCookieConfig
   */
  path?: string;
  /**
   *
   * @type {string}
   * @memberof SessionCookieConfig
   */
  domain?: string;
  /**
   *
   * @type {number}
   * @memberof SessionCookieConfig
   */
  maxAge?: number;
  /**
   *
   * @type {string}
   * @memberof SessionCookieConfig
   */
  name?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof SessionCookieConfig
   */
  attributes?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof SessionCookieConfig
   * @deprecated
   */
  comment?: string;
  /**
   *
   * @type {boolean}
   * @memberof SessionCookieConfig
   */
  httpOnly?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SessionCookieConfig
   */
  secure?: boolean;
}
/**
 *
 * @export
 * @interface SettingsResponse
 */
export interface SettingsResponse {
  /**
   *
   * @type {Array<EquipmentElem>}
   * @memberof SettingsResponse
   */
  equipment: Array<EquipmentElem>;
  /**
   *
   * @type {GeneralResponse}
   * @memberof SettingsResponse
   */
  response: GeneralResponse;
}
/**
 *
 * @export
 * @interface SocialMedia
 */
export interface SocialMedia {
  /**
   *
   * @type {string}
   * @memberof SocialMedia
   */
  instagram?: string;
}
/**
 *
 * @export
 * @interface TaglibDescriptor
 */
export interface TaglibDescriptor {
  /**
   *
   * @type {string}
   * @memberof TaglibDescriptor
   */
  taglibURI?: string;
  /**
   *
   * @type {string}
   * @memberof TaglibDescriptor
   */
  taglibLocation?: string;
}
/**
 *
 * @export
 * @interface UserDetailsRequest
 */
export interface UserDetailsRequest {
  /**
   *
   * @type {string}
   * @memberof UserDetailsRequest
   */
  instagram?: string;
}
/**
 *
 * @export
 * @interface UserDetailsResponse
 */
export interface UserDetailsResponse {
  /**
   *
   * @type {string}
   * @memberof UserDetailsResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailsResponse
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailsResponse
   */
  lastName?: string;
  /**
   *
   * @type {SocialMedia}
   * @memberof UserDetailsResponse
   */
  socialMedia?: SocialMedia;
  /**
   *
   * @type {string}
   * @memberof UserDetailsResponse
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailsResponse
   */
  expire: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserDetailsResponse
   */
  role: Array<string>;
  /**
   *
   * @type {GeneralResponse}
   * @memberof UserDetailsResponse
   */
  response: GeneralResponse;
}
/**
 *
 * @export
 * @interface WorkoutAssistantRequest
 */
export interface WorkoutAssistantRequest {
  /**
   *
   * @type {Array<ContentsRequest>}
   * @memberof WorkoutAssistantRequest
   */
  contents: Array<ContentsRequest>;
}
/**
 *
 * @export
 * @interface WorkoutAssistantResponse
 */
export interface WorkoutAssistantResponse {
  /**
   *
   * @type {string}
   * @memberof WorkoutAssistantResponse
   */
  text: string;
}
/**
 *
 * @export
 * @interface WorkoutAutoCreateRequest
 */
export interface WorkoutAutoCreateRequest {
  /**
   *
   * @type {number}
   * @memberof WorkoutAutoCreateRequest
   */
  numOfWorkouts: number;
  /**
   *
   * @type {string}
   * @memberof WorkoutAutoCreateRequest
   */
  type: WorkoutAutoCreateRequestTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof WorkoutAutoCreateRequest
   */
  save: boolean;
  /**
   *
   * @type {boolean}
   * @memberof WorkoutAutoCreateRequest
   */
  isHyrox: boolean;
  /**
   *
   * @type {string}
   * @memberof WorkoutAutoCreateRequest
   */
  difficulty: WorkoutAutoCreateRequestDifficultyEnum;
  /**
   *
   * @type {Array<number>}
   * @memberof WorkoutAutoCreateRequest
   */
  rangeOfRounds: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof WorkoutAutoCreateRequest
   */
  rangeOfDurations: Array<number>;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof WorkoutAutoCreateRequest
   */
  numOfMovementsPerType: { [key: string]: number };
}

export const WorkoutAutoCreateRequestTypeEnum = {
  Amrap: 'AMRAP',
  Chipper: 'CHIPPER',
  Emom: 'EMOM',
  Ladder: 'LADDER',
  Rft: 'RFT',
  Tabata: 'TABATA',
  Team: 'TEAM',
} as const;

export type WorkoutAutoCreateRequestTypeEnum =
  (typeof WorkoutAutoCreateRequestTypeEnum)[keyof typeof WorkoutAutoCreateRequestTypeEnum];
export const WorkoutAutoCreateRequestDifficultyEnum = {
  Beginner: 'BEGINNER',
  Basic: 'BASIC',
  Intermediate: 'INTERMEDIATE',
  Advanced: 'ADVANCED',
  Expert: 'EXPERT',
} as const;

export type WorkoutAutoCreateRequestDifficultyEnum =
  (typeof WorkoutAutoCreateRequestDifficultyEnum)[keyof typeof WorkoutAutoCreateRequestDifficultyEnum];

/**
 *
 * @export
 * @interface WorkoutAutoCreateResponse
 */
export interface WorkoutAutoCreateResponse {
  /**
   *
   * @type {Array<WorkoutElem>}
   * @memberof WorkoutAutoCreateResponse
   */
  workouts: Array<WorkoutElem>;
  /**
   *
   * @type {GeneralResponse}
   * @memberof WorkoutAutoCreateResponse
   */
  response: GeneralResponse;
}
/**
 *
 * @export
 * @interface WorkoutCreatorRequest
 */
export interface WorkoutCreatorRequest {
  /**
   *
   * @type {string}
   * @memberof WorkoutCreatorRequest
   */
  workoutName: string;
  /**
   *
   * @type {number}
   * @memberof WorkoutCreatorRequest
   */
  workoutDuration?: number;
  /**
   *
   * @type {number}
   * @memberof WorkoutCreatorRequest
   */
  workoutRounds?: number;
  /**
   *
   * @type {string}
   * @memberof WorkoutCreatorRequest
   */
  workoutType: string;
  /**
   *
   * @type {string}
   * @memberof WorkoutCreatorRequest
   */
  workoutDifficulty: string;
  /**
   *
   * @type {string}
   * @memberof WorkoutCreatorRequest
   */
  workoutNote?: string;
  /**
   *
   * @type {boolean}
   * @memberof WorkoutCreatorRequest
   */
  isHyrox: boolean;
  /**
   *
   * @type {Array<WorkoutElemMovement>}
   * @memberof WorkoutCreatorRequest
   */
  workoutMovementsRX: Array<WorkoutElemMovement>;
  /**
   *
   * @type {Array<WorkoutElemMovement>}
   * @memberof WorkoutCreatorRequest
   */
  workoutMovementsScale: Array<WorkoutElemMovement>;
}
/**
 *
 * @export
 * @interface WorkoutCreatorResponse
 */
export interface WorkoutCreatorResponse {
  /**
   *
   * @type {string}
   * @memberof WorkoutCreatorResponse
   */
  uuid?: string;
  /**
   *
   * @type {GeneralResponse}
   * @memberof WorkoutCreatorResponse
   */
  response: GeneralResponse;
}
/**
 *
 * @export
 * @interface WorkoutElem
 */
export interface WorkoutElem {
  /**
   *
   * @type {string}
   * @memberof WorkoutElem
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof WorkoutElem
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof WorkoutElem
   */
  difficulty: string;
  /**
   *
   * @type {number}
   * @memberof WorkoutElem
   */
  duration?: number;
  /**
   *
   * @type {number}
   * @memberof WorkoutElem
   */
  numOfRounds?: number;
  /**
   *
   * @type {string}
   * @memberof WorkoutElem
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof WorkoutElem
   */
  notes?: string;
  /**
   *
   * @type {Array<WorkoutElemMovement>}
   * @memberof WorkoutElem
   */
  movementsRx: Array<WorkoutElemMovement>;
  /**
   *
   * @type {Array<WorkoutElemMovement>}
   * @memberof WorkoutElem
   */
  movementsScale: Array<WorkoutElemMovement>;
  /**
   *
   * @type {Metadata}
   * @memberof WorkoutElem
   */
  metadata: Metadata;
}
/**
 *
 * @export
 * @interface WorkoutElemMovement
 */
export interface WorkoutElemMovement {
  /**
   *
   * @type {string}
   * @memberof WorkoutElemMovement
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof WorkoutElemMovement
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof WorkoutElemMovement
   */
  unit: string;
  /**
   *
   * @type {number}
   * @memberof WorkoutElemMovement
   */
  loadMan?: number;
  /**
   *
   * @type {number}
   * @memberof WorkoutElemMovement
   */
  loadWoman?: number;
  /**
   *
   * @type {number}
   * @memberof WorkoutElemMovement
   */
  duration?: number;
  /**
   *
   * @type {number}
   * @memberof WorkoutElemMovement
   */
  distance?: number;
  /**
   *
   * @type {string}
   * @memberof WorkoutElemMovement
   */
  numOfReps?: string;
}
/**
 *
 * @export
 * @interface WorkoutGeneratorResponse
 */
export interface WorkoutGeneratorResponse {
  /**
   *
   * @type {Array<WorkoutElem>}
   * @memberof WorkoutGeneratorResponse
   */
  workouts: Array<WorkoutElem>;
  /**
   *
   * @type {GeneralResponse}
   * @memberof WorkoutGeneratorResponse
   */
  response: GeneralResponse;
}
/**
 *
 * @export
 * @interface WorkoutResponse
 */
export interface WorkoutResponse {
  /**
   *
   * @type {WorkoutElem}
   * @memberof WorkoutResponse
   */
  workout: WorkoutElem;
  /**
   *
   * @type {GeneralResponse}
   * @memberof WorkoutResponse
   */
  response: GeneralResponse;
}
/**
 *
 * @export
 * @interface WorkoutsApproveRequest
 */
export interface WorkoutsApproveRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof WorkoutsApproveRequest
   */
  workoutIds: Array<string>;
}
/**
 *
 * @export
 * @interface WorkoutsApproveResponse
 */
export interface WorkoutsApproveResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof WorkoutsApproveResponse
   */
  workoutIds: Array<string>;
  /**
   *
   * @type {GeneralResponse}
   * @memberof WorkoutsApproveResponse
   */
  response: GeneralResponse;
}
/**
 *
 * @export
 * @interface WorkoutsResponse
 */
export interface WorkoutsResponse {
  /**
   *
   * @type {Array<WorkoutElem>}
   * @memberof WorkoutsResponse
   */
  workouts: Array<WorkoutElem>;
  /**
   *
   * @type {MetadataPageable}
   * @memberof WorkoutsResponse
   */
  metadata?: MetadataPageable;
  /**
   *
   * @type {GeneralResponse}
   * @memberof WorkoutsResponse
   */
  response: GeneralResponse;
}

/**
 * AuthenticationControllerApi - axios parameter creator
 * @export
 */
export const AuthenticationControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGoogleAuthToken: async (
      code: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('getGoogleAuthToken', 'code', code);
      const localVarPath = `/api/auth/google/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGoogleAuthUrl: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/google/url`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} username
     * @param {string} password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToken: async (
      username: string,
      password: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists('getToken', 'username', username);
      // verify required parameter 'password' is not null or undefined
      assertParamExists('getToken', 'password', password);
      const localVarPath = `/api/auth/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (username !== undefined) {
        localVarQueryParameter['username'] = username;
      }

      if (password !== undefined) {
        localVarQueryParameter['password'] = password;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthenticationControllerApi - functional programming interface
 * @export
 */
export const AuthenticationControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    AuthenticationControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGoogleAuthToken(
      code: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getGoogleAuthToken(code, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGoogleAuthUrl(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getGoogleAuthUrl(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} username
     * @param {string} password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getToken(
      username: string,
      password: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getToken(
        username,
        password,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AuthenticationControllerApi - factory interface
 * @export
 */
export const AuthenticationControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuthenticationControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGoogleAuthToken(code: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .getGoogleAuthToken(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGoogleAuthUrl(options?: any): AxiosPromise<string> {
      return localVarFp
        .getGoogleAuthUrl(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} username
     * @param {string} password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToken(
      username: string,
      password: string,
      options?: any,
    ): AxiosPromise<LoginResponse> {
      return localVarFp
        .getToken(username, password, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthenticationControllerApi - object-oriented interface
 * @export
 * @class AuthenticationControllerApi
 * @extends {BaseAPI}
 */
export class AuthenticationControllerApi extends BaseAPI {
  /**
   *
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationControllerApi
   */
  public getGoogleAuthToken(code: string, options?: AxiosRequestConfig) {
    return AuthenticationControllerApiFp(this.configuration)
      .getGoogleAuthToken(code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationControllerApi
   */
  public getGoogleAuthUrl(options?: AxiosRequestConfig) {
    return AuthenticationControllerApiFp(this.configuration)
      .getGoogleAuthUrl(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} username
   * @param {string} password
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationControllerApi
   */
  public getToken(
    username: string,
    password: string,
    options?: AxiosRequestConfig,
  ) {
    return AuthenticationControllerApiFp(this.configuration)
      .getToken(username, password, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MovementsControllerApi - axios parameter creator
 * @export
 */
export const MovementsControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {MovementRequest} movementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMovement: async (
      movementRequest: MovementRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'movementRequest' is not null or undefined
      assertParamExists('createMovement', 'movementRequest', movementRequest);
      const localVarPath = `/api/movements/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        movementRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MovementRequest} movementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMovement: async (
      movementRequest: MovementRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'movementRequest' is not null or undefined
      assertParamExists('deleteMovement', 'movementRequest', movementRequest);
      const localVarPath = `/api/movements/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        movementRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMovements: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/movements/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [currentPage]
     * @param {number} [sizePerPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMovements1: async (
      currentPage?: number,
      sizePerPage?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/movements/getAllByPageable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (currentPage !== undefined) {
        localVarQueryParameter['currentPage'] = currentPage;
      }

      if (sizePerPage !== undefined) {
        localVarQueryParameter['sizePerPage'] = sizePerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMovementByUuid: async (
      uuid: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists('getMovementByUuid', 'uuid', uuid);
      const localVarPath = `/api/movements/getByUuid`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (uuid !== undefined) {
        localVarQueryParameter['uuid'] = uuid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MovementRequest} movementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMovement: async (
      movementRequest: MovementRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'movementRequest' is not null or undefined
      assertParamExists('updateMovement', 'movementRequest', movementRequest);
      const localVarPath = `/api/movements/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        movementRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MovementsControllerApi - functional programming interface
 * @export
 */
export const MovementsControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    MovementsControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {MovementRequest} movementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMovement(
      movementRequest: MovementRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MovementResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMovement(
        movementRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {MovementRequest} movementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteMovement(
      movementRequest: MovementRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MovementResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMovement(
        movementRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllMovements(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MovementResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMovements(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [currentPage]
     * @param {number} [sizePerPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllMovements1(
      currentPage?: number,
      sizePerPage?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MovementResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllMovements1(
          currentPage,
          sizePerPage,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMovementByUuid(
      uuid: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MovementResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMovementByUuid(uuid, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {MovementRequest} movementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMovement(
      movementRequest: MovementRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MovementResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateMovement(
        movementRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * MovementsControllerApi - factory interface
 * @export
 */
export const MovementsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MovementsControllerApiFp(configuration);
  return {
    /**
     *
     * @param {MovementRequest} movementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMovement(
      movementRequest: MovementRequest,
      options?: any,
    ): AxiosPromise<MovementResponse> {
      return localVarFp
        .createMovement(movementRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {MovementRequest} movementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMovement(
      movementRequest: MovementRequest,
      options?: any,
    ): AxiosPromise<MovementResponse> {
      return localVarFp
        .deleteMovement(movementRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMovements(options?: any): AxiosPromise<MovementResponse> {
      return localVarFp
        .getAllMovements(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [currentPage]
     * @param {number} [sizePerPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMovements1(
      currentPage?: number,
      sizePerPage?: number,
      options?: any,
    ): AxiosPromise<MovementResponse> {
      return localVarFp
        .getAllMovements1(currentPage, sizePerPage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMovementByUuid(
      uuid: string,
      options?: any,
    ): AxiosPromise<MovementResponse> {
      return localVarFp
        .getMovementByUuid(uuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {MovementRequest} movementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMovement(
      movementRequest: MovementRequest,
      options?: any,
    ): AxiosPromise<MovementResponse> {
      return localVarFp
        .updateMovement(movementRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MovementsControllerApi - object-oriented interface
 * @export
 * @class MovementsControllerApi
 * @extends {BaseAPI}
 */
export class MovementsControllerApi extends BaseAPI {
  /**
   *
   * @param {MovementRequest} movementRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MovementsControllerApi
   */
  public createMovement(
    movementRequest: MovementRequest,
    options?: AxiosRequestConfig,
  ) {
    return MovementsControllerApiFp(this.configuration)
      .createMovement(movementRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MovementRequest} movementRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MovementsControllerApi
   */
  public deleteMovement(
    movementRequest: MovementRequest,
    options?: AxiosRequestConfig,
  ) {
    return MovementsControllerApiFp(this.configuration)
      .deleteMovement(movementRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MovementsControllerApi
   */
  public getAllMovements(options?: AxiosRequestConfig) {
    return MovementsControllerApiFp(this.configuration)
      .getAllMovements(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [currentPage]
   * @param {number} [sizePerPage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MovementsControllerApi
   */
  public getAllMovements1(
    currentPage?: number,
    sizePerPage?: number,
    options?: AxiosRequestConfig,
  ) {
    return MovementsControllerApiFp(this.configuration)
      .getAllMovements1(currentPage, sizePerPage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MovementsControllerApi
   */
  public getMovementByUuid(uuid: string, options?: AxiosRequestConfig) {
    return MovementsControllerApiFp(this.configuration)
      .getMovementByUuid(uuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MovementRequest} movementRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MovementsControllerApi
   */
  public updateMovement(
    movementRequest: MovementRequest,
    options?: AxiosRequestConfig,
  ) {
    return MovementsControllerApiFp(this.configuration)
      .updateMovement(movementRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaymentControllerApi - axios parameter creator
 * @export
 */
export const PaymentControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} priceId
     * @param {string} customerEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCheckoutSession: async (
      priceId: string,
      customerEmail: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'priceId' is not null or undefined
      assertParamExists('createCheckoutSession', 'priceId', priceId);
      // verify required parameter 'customerEmail' is not null or undefined
      assertParamExists(
        'createCheckoutSession',
        'customerEmail',
        customerEmail,
      );
      const localVarPath = `/api/payments/create-checkout-session`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (priceId !== undefined) {
        localVarQueryParameter['priceId'] = priceId;
      }

      if (customerEmail !== undefined) {
        localVarQueryParameter['customerEmail'] = customerEmail;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhook: async (
      body: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('webhook', 'body', body);
      const localVarPath = `/api/payments/webhook`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentControllerApi - functional programming interface
 * @export
 */
export const PaymentControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PaymentControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} priceId
     * @param {string} customerEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCheckoutSession(
      priceId: string,
      customerEmail: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedirectView>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createCheckoutSession(
          priceId,
          customerEmail,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async webhook(
      body: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.webhook(
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PaymentControllerApi - factory interface
 * @export
 */
export const PaymentControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaymentControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} priceId
     * @param {string} customerEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCheckoutSession(
      priceId: string,
      customerEmail: string,
      options?: any,
    ): AxiosPromise<RedirectView> {
      return localVarFp
        .createCheckoutSession(priceId, customerEmail, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhook(body: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .webhook(body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PaymentControllerApi - object-oriented interface
 * @export
 * @class PaymentControllerApi
 * @extends {BaseAPI}
 */
export class PaymentControllerApi extends BaseAPI {
  /**
   *
   * @param {string} priceId
   * @param {string} customerEmail
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentControllerApi
   */
  public createCheckoutSession(
    priceId: string,
    customerEmail: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentControllerApiFp(this.configuration)
      .createCheckoutSession(priceId, customerEmail, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentControllerApi
   */
  public webhook(body: string, options?: AxiosRequestConfig) {
    return PaymentControllerApiFp(this.configuration)
      .webhook(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SettingsControllerApi - axios parameter creator
 * @export
 */
export const SettingsControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEquipments: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/settings/equipments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SettingsControllerApi - functional programming interface
 * @export
 */
export const SettingsControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    SettingsControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEquipments(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SettingsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEquipments(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SettingsControllerApi - factory interface
 * @export
 */
export const SettingsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SettingsControllerApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEquipments(options?: any): AxiosPromise<SettingsResponse> {
      return localVarFp
        .getEquipments(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SettingsControllerApi - object-oriented interface
 * @export
 * @class SettingsControllerApi
 * @extends {BaseAPI}
 */
export class SettingsControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsControllerApi
   */
  public getEquipments(options?: AxiosRequestConfig) {
    return SettingsControllerApiFp(this.configuration)
      .getEquipments(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserDetails: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/user/details`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserDetailsRequest} userDetailsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserDetails: async (
      userDetailsRequest: UserDetailsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userDetailsRequest' is not null or undefined
      assertParamExists(
        'updateUserDetails',
        'userDetailsRequest',
        userDetailsRequest,
      );
      const localVarPath = `/api/user/details`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userDetailsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UserControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserDetails(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserDetailsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDetails(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UserDetailsRequest} userDetailsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserDetails(
      userDetailsRequest: UserDetailsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserDetailsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserDetails(
          userDetailsRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserControllerApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserDetails(options?: any): AxiosPromise<UserDetailsResponse> {
      return localVarFp
        .getUserDetails(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UserDetailsRequest} userDetailsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserDetails(
      userDetailsRequest: UserDetailsRequest,
      options?: any,
    ): AxiosPromise<UserDetailsResponse> {
      return localVarFp
        .updateUserDetails(userDetailsRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public getUserDetails(options?: AxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .getUserDetails(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserDetailsRequest} userDetailsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public updateUserDetails(
    userDetailsRequest: UserDetailsRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .updateUserDetails(userDetailsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WorkoutAssistantControllerApi - axios parameter creator
 * @export
 */
export const WorkoutAssistantControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {WorkoutAssistantRequest} workoutAssistantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promptAssistant: async (
      workoutAssistantRequest: WorkoutAssistantRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workoutAssistantRequest' is not null or undefined
      assertParamExists(
        'promptAssistant',
        'workoutAssistantRequest',
        workoutAssistantRequest,
      );
      const localVarPath = `/api/workouts/assistant`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        workoutAssistantRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WorkoutAssistantControllerApi - functional programming interface
 * @export
 */
export const WorkoutAssistantControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    WorkoutAssistantControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {WorkoutAssistantRequest} workoutAssistantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async promptAssistant(
      workoutAssistantRequest: WorkoutAssistantRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WorkoutAssistantResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.promptAssistant(
        workoutAssistantRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * WorkoutAssistantControllerApi - factory interface
 * @export
 */
export const WorkoutAssistantControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WorkoutAssistantControllerApiFp(configuration);
  return {
    /**
     *
     * @param {WorkoutAssistantRequest} workoutAssistantRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promptAssistant(
      workoutAssistantRequest: WorkoutAssistantRequest,
      options?: any,
    ): AxiosPromise<WorkoutAssistantResponse> {
      return localVarFp
        .promptAssistant(workoutAssistantRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * WorkoutAssistantControllerApi - object-oriented interface
 * @export
 * @class WorkoutAssistantControllerApi
 * @extends {BaseAPI}
 */
export class WorkoutAssistantControllerApi extends BaseAPI {
  /**
   *
   * @param {WorkoutAssistantRequest} workoutAssistantRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkoutAssistantControllerApi
   */
  public promptAssistant(
    workoutAssistantRequest: WorkoutAssistantRequest,
    options?: AxiosRequestConfig,
  ) {
    return WorkoutAssistantControllerApiFp(this.configuration)
      .promptAssistant(workoutAssistantRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WorkoutAutoCreateControllerApi - axios parameter creator
 * @export
 */
export const WorkoutAutoCreateControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {WorkoutAutoCreateRequest} workoutAutoCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    autoCreateWorkouts: async (
      workoutAutoCreateRequest: WorkoutAutoCreateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workoutAutoCreateRequest' is not null or undefined
      assertParamExists(
        'autoCreateWorkouts',
        'workoutAutoCreateRequest',
        workoutAutoCreateRequest,
      );
      const localVarPath = `/api/workouts/auto`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        workoutAutoCreateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WorkoutAutoCreateControllerApi - functional programming interface
 * @export
 */
export const WorkoutAutoCreateControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    WorkoutAutoCreateControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {WorkoutAutoCreateRequest} workoutAutoCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async autoCreateWorkouts(
      workoutAutoCreateRequest: WorkoutAutoCreateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WorkoutAutoCreateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.autoCreateWorkouts(
          workoutAutoCreateRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * WorkoutAutoCreateControllerApi - factory interface
 * @export
 */
export const WorkoutAutoCreateControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WorkoutAutoCreateControllerApiFp(configuration);
  return {
    /**
     *
     * @param {WorkoutAutoCreateRequest} workoutAutoCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    autoCreateWorkouts(
      workoutAutoCreateRequest: WorkoutAutoCreateRequest,
      options?: any,
    ): AxiosPromise<WorkoutAutoCreateResponse> {
      return localVarFp
        .autoCreateWorkouts(workoutAutoCreateRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * WorkoutAutoCreateControllerApi - object-oriented interface
 * @export
 * @class WorkoutAutoCreateControllerApi
 * @extends {BaseAPI}
 */
export class WorkoutAutoCreateControllerApi extends BaseAPI {
  /**
   *
   * @param {WorkoutAutoCreateRequest} workoutAutoCreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkoutAutoCreateControllerApi
   */
  public autoCreateWorkouts(
    workoutAutoCreateRequest: WorkoutAutoCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return WorkoutAutoCreateControllerApiFp(this.configuration)
      .autoCreateWorkouts(workoutAutoCreateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WorkoutControllerApi - axios parameter creator
 * @export
 */
export const WorkoutControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {WorkoutsApproveRequest} workoutsApproveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveWorkout: async (
      workoutsApproveRequest: WorkoutsApproveRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workoutsApproveRequest' is not null or undefined
      assertParamExists(
        'approveWorkout',
        'workoutsApproveRequest',
        workoutsApproveRequest,
      );
      const localVarPath = `/api/workouts/approve`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        workoutsApproveRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {WorkoutCreatorRequest} workoutCreatorRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWorkout: async (
      workoutCreatorRequest: WorkoutCreatorRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workoutCreatorRequest' is not null or undefined
      assertParamExists(
        'createWorkout',
        'workoutCreatorRequest',
        workoutCreatorRequest,
      );
      const localVarPath = `/api/workouts/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        workoutCreatorRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWorkout: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteWorkout', 'id', id);
      const localVarPath = `/api/workouts/delete/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkout: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getWorkout', 'id', id);
      const localVarPath = `/api/workouts/get/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [currentPage]
     * @param {number} [sizePerPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkouts: async (
      currentPage?: number,
      sizePerPage?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/workouts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (currentPage !== undefined) {
        localVarQueryParameter['currentPage'] = currentPage;
      }

      if (sizePerPage !== undefined) {
        localVarQueryParameter['sizePerPage'] = sizePerPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WorkoutControllerApi - functional programming interface
 * @export
 */
export const WorkoutControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    WorkoutControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {WorkoutsApproveRequest} workoutsApproveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveWorkout(
      workoutsApproveRequest: WorkoutsApproveRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WorkoutsApproveResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approveWorkout(
        workoutsApproveRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {WorkoutCreatorRequest} workoutCreatorRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createWorkout(
      workoutCreatorRequest: WorkoutCreatorRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WorkoutCreatorResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkout(
        workoutCreatorRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteWorkout(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkout(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkout(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WorkoutResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkout(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [currentPage]
     * @param {number} [sizePerPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkouts(
      currentPage?: number,
      sizePerPage?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WorkoutsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkouts(
        currentPage,
        sizePerPage,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * WorkoutControllerApi - factory interface
 * @export
 */
export const WorkoutControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WorkoutControllerApiFp(configuration);
  return {
    /**
     *
     * @param {WorkoutsApproveRequest} workoutsApproveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveWorkout(
      workoutsApproveRequest: WorkoutsApproveRequest,
      options?: any,
    ): AxiosPromise<WorkoutsApproveResponse> {
      return localVarFp
        .approveWorkout(workoutsApproveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {WorkoutCreatorRequest} workoutCreatorRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWorkout(
      workoutCreatorRequest: WorkoutCreatorRequest,
      options?: any,
    ): AxiosPromise<WorkoutCreatorResponse> {
      return localVarFp
        .createWorkout(workoutCreatorRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWorkout(id: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .deleteWorkout(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkout(id: string, options?: any): AxiosPromise<WorkoutResponse> {
      return localVarFp
        .getWorkout(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [currentPage]
     * @param {number} [sizePerPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkouts(
      currentPage?: number,
      sizePerPage?: number,
      options?: any,
    ): AxiosPromise<WorkoutsResponse> {
      return localVarFp
        .getWorkouts(currentPage, sizePerPage, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * WorkoutControllerApi - object-oriented interface
 * @export
 * @class WorkoutControllerApi
 * @extends {BaseAPI}
 */
export class WorkoutControllerApi extends BaseAPI {
  /**
   *
   * @param {WorkoutsApproveRequest} workoutsApproveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkoutControllerApi
   */
  public approveWorkout(
    workoutsApproveRequest: WorkoutsApproveRequest,
    options?: AxiosRequestConfig,
  ) {
    return WorkoutControllerApiFp(this.configuration)
      .approveWorkout(workoutsApproveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {WorkoutCreatorRequest} workoutCreatorRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkoutControllerApi
   */
  public createWorkout(
    workoutCreatorRequest: WorkoutCreatorRequest,
    options?: AxiosRequestConfig,
  ) {
    return WorkoutControllerApiFp(this.configuration)
      .createWorkout(workoutCreatorRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkoutControllerApi
   */
  public deleteWorkout(id: string, options?: AxiosRequestConfig) {
    return WorkoutControllerApiFp(this.configuration)
      .deleteWorkout(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkoutControllerApi
   */
  public getWorkout(id: string, options?: AxiosRequestConfig) {
    return WorkoutControllerApiFp(this.configuration)
      .getWorkout(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [currentPage]
   * @param {number} [sizePerPage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkoutControllerApi
   */
  public getWorkouts(
    currentPage?: number,
    sizePerPage?: number,
    options?: AxiosRequestConfig,
  ) {
    return WorkoutControllerApiFp(this.configuration)
      .getWorkouts(currentPage, sizePerPage, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WorkoutGeneratorControllerApi - axios parameter creator
 * @export
 */
export const WorkoutGeneratorControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} [workoutDuration]
     * @param {string} [workoutType]
     * @param {string} [level]
     * @param {boolean} [hyrox]
     * @param {Array<string>} [equipment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateRandomWorkout: async (
      workoutDuration?: number,
      workoutType?: string,
      level?: string,
      hyrox?: boolean,
      equipment?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/workouts/generate/random`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workoutDuration !== undefined) {
        localVarQueryParameter['workoutDuration'] = workoutDuration;
      }

      if (workoutType !== undefined) {
        localVarQueryParameter['workoutType'] = workoutType;
      }

      if (level !== undefined) {
        localVarQueryParameter['level'] = level;
      }

      if (hyrox !== undefined) {
        localVarQueryParameter['hyrox'] = hyrox;
      }

      if (equipment) {
        localVarQueryParameter['equipment'] = equipment;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WorkoutGeneratorControllerApi - functional programming interface
 * @export
 */
export const WorkoutGeneratorControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    WorkoutGeneratorControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} [workoutDuration]
     * @param {string} [workoutType]
     * @param {string} [level]
     * @param {boolean} [hyrox]
     * @param {Array<string>} [equipment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateRandomWorkout(
      workoutDuration?: number,
      workoutType?: string,
      level?: string,
      hyrox?: boolean,
      equipment?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WorkoutGeneratorResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.generateRandomWorkout(
          workoutDuration,
          workoutType,
          level,
          hyrox,
          equipment,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * WorkoutGeneratorControllerApi - factory interface
 * @export
 */
export const WorkoutGeneratorControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WorkoutGeneratorControllerApiFp(configuration);
  return {
    /**
     *
     * @param {number} [workoutDuration]
     * @param {string} [workoutType]
     * @param {string} [level]
     * @param {boolean} [hyrox]
     * @param {Array<string>} [equipment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateRandomWorkout(
      workoutDuration?: number,
      workoutType?: string,
      level?: string,
      hyrox?: boolean,
      equipment?: Array<string>,
      options?: any,
    ): AxiosPromise<WorkoutGeneratorResponse> {
      return localVarFp
        .generateRandomWorkout(
          workoutDuration,
          workoutType,
          level,
          hyrox,
          equipment,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * WorkoutGeneratorControllerApi - object-oriented interface
 * @export
 * @class WorkoutGeneratorControllerApi
 * @extends {BaseAPI}
 */
export class WorkoutGeneratorControllerApi extends BaseAPI {
  /**
   *
   * @param {number} [workoutDuration]
   * @param {string} [workoutType]
   * @param {string} [level]
   * @param {boolean} [hyrox]
   * @param {Array<string>} [equipment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkoutGeneratorControllerApi
   */
  public generateRandomWorkout(
    workoutDuration?: number,
    workoutType?: string,
    level?: string,
    hyrox?: boolean,
    equipment?: Array<string>,
    options?: AxiosRequestConfig,
  ) {
    return WorkoutGeneratorControllerApiFp(this.configuration)
      .generateRandomWorkout(
        workoutDuration,
        workoutType,
        level,
        hyrox,
        equipment,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
