import { ShoppingCart as SubscriptionIcon } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { GeneralCard } from '../../layout/components';
import { Uri } from '../../util/Uri';

const PRODUCT_ID =
  process.env.REACT_APP_PROFILE === 'dev'
    ? process.env.REACT_APP_STRIPE_SUBCRIPTION_PRODUCT_ID
    : process.env.REACT_APP_STRIPE_SUBCRIPTION_PRODUCT_ID_PROD;

const TESTIDS = {
  wrapper: 'wrapper-subscription',
  firstText: 'first-text',
  secondText: 'second-text',
  stripePriceId: 'stripe-price-id',
  stripeCustomerEmail: 'stripe-customer-email',
};

type Props = {
  customerEmail: string;
};

export const Subscription = ({ customerEmail }: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent="center"
      data-testid={TESTIDS.wrapper}
    >
      <GeneralCard>
        <Box textAlign="center">
          <Typography variant="body1" data-testid={TESTIDS.firstText}>
            Support this app and unlock the generate workouts using filters for
            0.99£ per month.
          </Typography>
          <Typography variant="body1" data-testid={TESTIDS.secondText}>
            You can try the subscription for one month for FREE!
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
          <form
            action={`${Uri.baseUriApp}/api/payments/create-checkout-session`}
            method="POST"
          >
            <input
              type="hidden"
              name="priceId"
              value={PRODUCT_ID}
              data-testid={TESTIDS.stripePriceId}
            />
            <input
              type="hidden"
              name="customerEmail"
              value={customerEmail}
              data-testid={TESTIDS.stripeCustomerEmail}
            />

            <Button
              type="submit"
              startIcon={<SubscriptionIcon sx={{ color: 'black' }} />}
            >
              <Typography variant="body1">Subscribe here</Typography>
            </Button>
          </form>
        </Box>
      </GeneralCard>
    </Box>
  );
};

Subscription.TESTIDS = TESTIDS;
