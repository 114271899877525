import { ApiContext, createApi } from './package/api/context';
import { ThemeProvider } from '@mui/system';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Seo } from './components/Seo';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import { theme } from './theme';
import { Uri } from './util/Uri';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

const appContainer = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const appRoot = createRoot(appContainer!);

appRoot.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <ApiContext.Provider
              value={createApi({
                thunderwod: {
                  uri: Uri.baseUriApp,
                },
              })}
            >
              <GoogleOAuthProvider
                clientId={`${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`}
              >
                <Seo
                  title="ThunderWOD - Start your fitness journey today, get a workout for free!"
                  description="Get a free workout in seconds! ThunderWOD allows you to generate a workout for free or if you prefer for 0.99£ per month you can unlock workout filtering."
                >
                  <App />
                </Seo>
              </GoogleOAuthProvider>
            </ApiContext.Provider>
          </QueryClientProvider>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
