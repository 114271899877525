import { WorkoutElem } from '@api/src/thunderwod';
import { useResponsive } from '@hooks/useMedia';
import {
  AddTaskRounded as ApproveWorkoutIcon,
  CheckCircleRounded as ApprovedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as ViewIcon,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  Pagination,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Table as TableMui,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { ConfirmationModal } from '../../../layout/components/ConfirmationModal';
import { TimeUtils } from '../../../util/TimeUtils';
import { Uri } from '../../../util/Uri';

interface Props {
  workouts: WorkoutElem[];
  currentPage: number;
  rowsPerPage: number;
  totalOfWorkouts: number;
  onRowsPerPageChange: (rowsPerPage: number) => void;
  onPageChange: (newPage: number) => void;
  onDelete: (uuid?: string) => void;
  onEdit: (uuid?: string) => void;
  onApprove: (uuid: string) => void;
}

const TESTIDS = {
  wrapper: 'wrapper-list-workouts',
  table: {
    header: {
      mobile: 'mobile-table-header',
      desktop: 'desktop-table-header',
    },
    body: {
      mobile: 'mobile-table-body',
      desktop: 'desktop-table-body',
      row: {
        workoutName: (rowNum: number) => `workout-name-${rowNum}`,
        workoutDuration: (rowNum: number) => `workout-duration-${rowNum}`,
        workoutRounds: (rowNum: number) => `workout-rounds-${rowNum}`,
        workoutType: (rowNum: number) => `workout-type-${rowNum}`,
        workoutStatus: (rowNum: number) => `workout-status-${rowNum}`,
        workoutPreview: (rowNum: number) => `workout-preview-${rowNum}`,
        workoutEdit: (rowNum: number) => `workout-edit-${rowNum}`,
        workoutDelete: (rowNum: number) => `workout-delete-${rowNum}`,
      },
    },
  },
  pagination: {
    mobile: 'mobile-pagination',
    desktop: 'desktop-pagination',
  },
};

export const Catalogue = ({
  workouts,
  currentPage,
  rowsPerPage,
  totalOfWorkouts,
  onRowsPerPageChange,
  onPageChange,
  onDelete,
  onEdit,
  onApprove,
}: Props) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [workoutToDelete, setWorkoutToDelete] = useState<string | undefined>(
    undefined,
  );

  const ismobile = useResponsive('down', 'sm');

  const handleOnDelete = (uuid?: string) => {
    if (uuid) {
      setIsConfirmationModalOpen(true);
      setWorkoutToDelete(uuid);
    }
  };

  const desktopHeaderTable = (
    <TableHead data-testid={TESTIDS.table.header.desktop}>
      <TableRow>
        <TableCell width={1 / 3}>Name</TableCell>
        <TableCell align="right" width={1 / 12}>
          Duration
        </TableCell>
        <TableCell align="right" width={1 / 12}>
          Num of Rounds
        </TableCell>
        <TableCell align="right" width={1 / 12}>
          Type
        </TableCell>
        <TableCell align="right" width={1 / 12}>
          Status
        </TableCell>
        <TableCell align="right" width={1 / 3}></TableCell>
      </TableRow>
    </TableHead>
  );

  const mobileHeaderTable = (
    <TableHead data-testid={TESTIDS.table.header.mobile}>
      <TableRow>
        <TableCell sx={{ textAlign: 'center' }} colSpan={2}>
          Workouts list
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const desktopTable = (
    <TableBody data-testid={TESTIDS.table.body.desktop}>
      {workouts.map((row, index) => {
        const duration = TimeUtils.convertSecondsToMinutes(row.duration);

        return (
          <TableRow
            key={`${row.id}_${row.type}`}
            //sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell data-testid={TESTIDS.table.body.row.workoutName(index)}>
              {row.description}
            </TableCell>
            <TableCell
              align="right"
              data-testid={TESTIDS.table.body.row.workoutDuration(index)}
            >
              {duration === '0 sec' ? '-' : duration}
            </TableCell>
            <TableCell
              align="right"
              data-testid={TESTIDS.table.body.row.workoutRounds(index)}
            >
              {row.numOfRounds ?? '-'}
            </TableCell>
            <TableCell
              align="right"
              data-testid={TESTIDS.table.body.row.workoutType(index)}
            >
              {row.type}
            </TableCell>
            <TableCell
              align="right"
              data-testid={TESTIDS.table.body.row.workoutStatus(index)}
            >
              {row.metadata.isApproved ? (
                <ApprovedIcon color="success" />
              ) : (
                <IconButton
                  sx={{ p: 0 }}
                  aria-label="approve"
                  onClick={() => onApprove(row.id ?? '')}
                >
                  <ApproveWorkoutIcon fontSize="inherit" color="warning" />
                </IconButton>
              )}
            </TableCell>
            <TableCell align="right">
              <IconButton
                aria-label="edit"
                size="large"
                data-testid={TESTIDS.table.body.row.workoutPreview(index)}
                onClick={() =>
                  window
                    .open(`${Uri.baseUri}/workout/preview/${row.id}`, '_blank')
                    ?.focus()
                }
              >
                <ViewIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                aria-label="edit"
                size="large"
                data-testid={TESTIDS.table.body.row.workoutEdit(index)}
                onClick={() => onEdit(row.id)}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                aria-label="delete"
                size="large"
                data-testid={TESTIDS.table.body.row.workoutDelete(index)}
                onClick={() => handleOnDelete(row.id)}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );

  const mobileTable = (
    <TableBody data-testid={TESTIDS.table.body.mobile}>
      {workouts.map((row, index) => {
        const duration = TimeUtils.convertSecondsToMinutes(row.duration);

        return (
          <>
            <TableRow>
              <TableCell
                data-testid={TESTIDS.table.body.row.workoutName(index)}
              >
                {row.description}
              </TableCell>
              <TableCell align="left">
                <TableMui size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        data-testid={TESTIDS.table.body.row.workoutDuration(
                          index,
                        )}
                      >
                        Duration: {duration === '0 sec' ? '-' : duration}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        data-testid={TESTIDS.table.body.row.workoutRounds(
                          index,
                        )}
                      >
                        Num of Rounds: {row.numOfRounds ?? '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        data-testid={TESTIDS.table.body.row.workoutType(index)}
                      >
                        Type: {row.type}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        data-testid={TESTIDS.table.body.row.workoutStatus(
                          index,
                        )}
                      >
                        {row.metadata.isApproved ? (
                          <ApprovedIcon color="success" />
                        ) : (
                          <IconButton
                            sx={{ p: 0 }}
                            aria-label="approve"
                            onClick={() => onApprove(row.id ?? '')}
                          >
                            <ApproveWorkoutIcon
                              fontSize="inherit"
                              color="warning"
                            />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </TableMui>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                <IconButton
                  aria-label="edit"
                  data-testid={TESTIDS.table.body.row.workoutPreview(index)}
                  onClick={() =>
                    window
                      .open(
                        `${Uri.baseUri}/workout/preview/${row.id}`,
                        '_blank',
                      )
                      ?.focus()
                  }
                >
                  <ViewIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  data-testid={TESTIDS.table.body.row.workoutEdit(index)}
                  onClick={() => onEdit(row.id)}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  data-testid={TESTIDS.table.body.row.workoutDelete(index)}
                  onClick={() => handleOnDelete(row.id)}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </TableCell>
            </TableRow>
          </>
        );
      })}
    </TableBody>
  );

  return (
    <TableContainer component={Paper} data-testid={TESTIDS.wrapper}>
      <ConfirmationModal
        open={isConfirmationModalOpen}
        actionTitle="Delete Workout"
        actionDescription={`Are you sure you want to delete the workout ${workoutToDelete}?`}
        onClose={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
        onConfirmation={() => {
          onDelete(workoutToDelete);
          setIsConfirmationModalOpen(!isConfirmationModalOpen);
        }}
      />

      {/* Table */}
      <TableMui size="small">
        {ismobile ? mobileHeaderTable : desktopHeaderTable}
        {ismobile ? mobileTable : desktopTable}
      </TableMui>

      {/* Pagination */}
      {ismobile ? (
        <Box
          display="flex"
          justifyContent="center"
          py={2}
          data-testid={TESTIDS.pagination.mobile}
        >
          <Pagination
            count={Math.ceil(totalOfWorkouts / rowsPerPage)}
            page={currentPage}
            onChange={(_, newPage: number) => {
              onPageChange(newPage);
            }}
            showFirstButton
            showLastButton
          />
        </Box>
      ) : (
        <TablePagination
          data-testid={TESTIDS.pagination.desktop}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalOfWorkouts}
          rowsPerPage={rowsPerPage}
          page={currentPage - 1}
          showFirstButton
          showLastButton
          onPageChange={(_, newPage: number) => onPageChange(newPage + 1)}
          onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onRowsPerPageChange(parseInt(event.target.value, 10))
          }
        />
      )}
    </TableContainer>
  );
};

Catalogue.TESTIDS = TESTIDS;
