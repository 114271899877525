import { Inputs } from '.';
import {
  UserDetailsRequest,
  UserDetailsResponse,
} from '../../../package/api/src/thunderwod';

const toApi = (data: Inputs): UserDetailsRequest => {
  return {
    instagram: data.instagram,
  };
};

const toForm = (userDetails: UserDetailsResponse): Inputs => {
  return {
    email: userDetails.email ?? '',
    firstName: userDetails.firstName ?? '',
    lastName: userDetails.lastName ?? '',
    instagram: userDetails.socialMedia?.instagram,
  };
};
export const FormAccountTransformer = {
  toApi,
  toForm,
};
