import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import Markdown from 'react-markdown';
import { MovementElem } from '../../../package/api/src/thunderwod';
import { WorkoutMovement, WorkoutMovementUnit } from '../../../types';
import { MovementsTable } from './Movements';

export type Inputs = {
  name: string;
  isHyrox: string;
  type: string;
  difficulty: string;
  duration: number;
  rounds: number;
  notes: string;
  movements: WorkoutMovement[];
};

export enum MovementField {
  'NUM_OF_REPS',
  'DURATION',
  'DISTANCE',
  'LOAD_MAN',
  'LOAD_WOMAN',
}

interface Props {
  listOfMovements: MovementElem[];
  defaultValues?: Inputs;
  isSaveEnabled: boolean;
  onChange: (data: Inputs) => void;
  onSave: (data: Inputs) => void;
}

const TESTIDS = {
  wrapper: 'wrapper-workout-builder',
  labels: {
    workout: 'builder-label-workout',
    exercise: 'builder-label-exercise',
  },
  form: {
    workoutName: 'builder-form-workout-name',
    workoutDifficulty: 'builder-form-workout-difficulty',
    workoutType: 'builder-form-workout-type',
    workoutDuration: 'builder-form-workout-duration',
    workoutRounds: 'builder-form-workout-rounds',
    workoutNotes: 'builder-form-workout-notes',
    workoutNotesPreview: 'builder-form-workout-notes-preview',
    workoutHyrox: 'builder-form-workout-hyrox',
    exerciseSearch: 'builder-form-exercise-search',
    exerciseUnit: 'builder-form-exercise-unit',
    exerciseReps: 'builder-form-exercise-reps',
    exerciseDuration: 'builder-form-exercise-duration',
    exerciseDistance: 'builder-form-exercise-distance',
    exerciseLoadMan: 'builder-form-exercise-loadMan',
    exerciseLoadWoman: 'builder-form-exercise-loadWoman',
    btn: {
      execise: 'builder-form-exercise-add',
      save: 'builder-form-save',
    },
  },
};

export const WorkoutBuilder = ({
  listOfMovements,
  defaultValues,
  isSaveEnabled,
  onChange,
  onSave,
}: Props) => {
  const theme = useTheme();

  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    control,
  } = useForm<Inputs>({
    defaultValues: {
      name: defaultValues?.name ?? '',
      difficulty: defaultValues?.difficulty ?? 'beginner',
      duration: defaultValues?.duration ?? 0,
      rounds: defaultValues?.rounds ?? 0,
      type: defaultValues?.type ?? 'amrap',
      notes: defaultValues?.notes ?? '',
      movements: defaultValues?.movements ?? [],
    },
  });

  const [selectedMovementElem, setSelectedMovementElem] =
    useState<MovementElem>();
  const [selectedUnit, setSelectedUnit] =
    useState<WorkoutMovementUnit>('total');
  const [numOfReps, setNumOfReps] = useState<number | undefined>(undefined);
  const [duration, setDuration] = useState<number | undefined>(undefined);
  const [distance, setDistance] = useState<number | undefined>(undefined);
  const [loadMan, setLoadMan] = useState<number | undefined>(undefined);
  const [loadWoman, setLoadWoman] = useState<number | undefined>(undefined);

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => onSave(data);

  useEffect(() => {
    if (!defaultValues) {
      // reset form fields
      reset({
        name: '',
        isHyrox: 'false',
        difficulty: 'beginner',
        duration: 0,
        rounds: 0,
        type: 'amrap',
        notes: '',
        movements: [],
      });
    }
  }, [defaultValues]);

  useEffect(() => {
    const subscription = watch((value) => onChange(value as Inputs));
    return () => subscription.unsubscribe();
  }, [watch]);

  const {
    fields: workoutMovement,
    append: appendWorkoutMovement,
    remove: removeWorkoutMovement,
    update: updateWorkoutMovement,
    swap: swapWorkoutMovement,
  } = useFieldArray({
    control,
    name: 'movements',
  });

  const handleAddNewMovement = () => {
    if (selectedMovementElem) {
      const newMovement: WorkoutMovement = {
        uuid: selectedMovementElem.uuid ?? '',
        name: selectedMovementElem.name ?? '',
        duration,
        distance,
        loadMan,
        loadWoman,
        numOfReps: numOfReps ? `${numOfReps}` : undefined,
        unit: selectedUnit,
      };

      appendWorkoutMovement(newMovement);
    }
  };

  const handleOnDeleteMovement = (rowIndex: number) => {
    removeWorkoutMovement(rowIndex);
  };

  const handleOnSwapMovement = (rowIndex: number, swap: string) => {
    if (swap === 'up') swapWorkoutMovement(rowIndex, rowIndex - 1);
    if (swap === 'down') swapWorkoutMovement(rowIndex, rowIndex + 1);
  };

  const handleChangeMovementField = (
    rowIndex: number,
    field: MovementField,
    newValue: string,
  ) => {
    switch (field) {
      case MovementField.NUM_OF_REPS:
        updateWorkoutMovement(rowIndex, {
          ...workoutMovement[rowIndex],
          numOfReps: newValue,
        });
        break;
      case MovementField.DISTANCE:
        updateWorkoutMovement(rowIndex, {
          ...workoutMovement[rowIndex],
          distance: parseInt(newValue),
        });
        break;
      case MovementField.DURATION:
        updateWorkoutMovement(rowIndex, {
          ...workoutMovement[rowIndex],
          duration: parseInt(newValue),
        });
        break;
      case MovementField.LOAD_MAN:
        updateWorkoutMovement(rowIndex, {
          ...workoutMovement[rowIndex],
          loadMan: parseFloat(newValue),
        });
        break;
      case MovementField.LOAD_WOMAN:
        updateWorkoutMovement(rowIndex, {
          ...workoutMovement[rowIndex],
          loadWoman: parseFloat(newValue),
        });
        break;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid={TESTIDS.wrapper}>
      <Typography component="h3" mb={2} data-testid={TESTIDS.labels.workout}>
        Workout info:
      </Typography>

      <Grid container spacing={2}>
        {/* Workout name */}
        <Grid item xs={12} md={12}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: 'Please write a workout name!',
            }}
            render={({ field: { ...field } }) => {
              return (
                <TextField
                  {...field}
                  label="Name"
                  sx={{ width: 1 }}
                  inputProps={{ 'data-testid': TESTIDS.form.workoutName }}
                />
              );
            }}
          />
          {errors.name && (
            <FormHelperText error required>
              {errors.name.message}
            </FormHelperText>
          )}
        </Grid>

        {/* Workout Hyrox */}
        <Grid item xs={12} md={12}>
          <Controller
            name="isHyrox"
            control={control}
            render={({ field: { ...field } }) => {
              return (
                <FormControl sx={{ width: 1 }}>
                  <InputLabel id="workout-hyrox">Hyrox</InputLabel>
                  <Select
                    {...field}
                    labelId="workout-hyrox"
                    label="Hyrox"
                    defaultValue="false"
                    color="primary"
                    inputProps={{
                      'data-testid': TESTIDS.form.workoutHyrox,
                    }}
                  >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </FormControl>
              );
            }}
          />
        </Grid>

        {/* Workout difficulty */}
        <Grid item xs={12} md={6}>
          <Controller
            name="difficulty"
            control={control}
            render={({ field: { ...field } }) => {
              return (
                <FormControl sx={{ width: 1 }}>
                  <InputLabel id="workout-difficulty">Difficulty</InputLabel>
                  <Select
                    {...field}
                    labelId="workout-difficulty"
                    label="Difficulty"
                    defaultValue="beginner"
                    color="primary"
                    inputProps={{
                      'data-testid': TESTIDS.form.workoutDifficulty,
                    }}
                  >
                    <MenuItem value="beginner">Beginner</MenuItem>
                    <MenuItem value="basic">Basic</MenuItem>
                    <MenuItem value="intermediate">Intermediate</MenuItem>
                    <MenuItem value="advanced">Advanced</MenuItem>
                    <MenuItem value="expert">Expert</MenuItem>
                  </Select>
                </FormControl>
              );
            }}
          />
        </Grid>

        {/* Workout type */}
        <Grid item xs={12} md={6}>
          <Controller
            name="type"
            control={control}
            render={({ field: { ...field } }) => {
              return (
                <FormControl sx={{ width: 1 }}>
                  <InputLabel id="workout-type">Type</InputLabel>
                  <Select
                    {...field}
                    labelId="workout-type"
                    label="Type"
                    defaultValue="amrap"
                    color="primary"
                    inputProps={{ 'data-testid': TESTIDS.form.workoutType }}
                  >
                    <MenuItem value="amrap">AMRAP</MenuItem>
                    <MenuItem value="emom">EMOM</MenuItem>
                    <MenuItem value="rft">RFT</MenuItem>
                    <MenuItem value="tabata">TABATA</MenuItem>
                  </Select>
                </FormControl>
              );
            }}
          />
        </Grid>

        {/* Workout duration */}
        <Grid item xs={12} md={6}>
          <Controller
            name="duration"
            control={control}
            render={({ field: { ...field } }) => {
              return (
                <TextField
                  {...field}
                  label="Duration"
                  type="number"
                  sx={{ width: 1 }}
                  inputProps={{ 'data-testid': TESTIDS.form.workoutDuration }}
                />
              );
            }}
          />
        </Grid>

        {/* Workout rounds */}
        <Grid item xs={12} md={6}>
          <Controller
            name="rounds"
            control={control}
            render={({ field: { ...field } }) => {
              return (
                <TextField
                  {...field}
                  label="Num of rounds"
                  type="number"
                  sx={{ width: 1 }}
                  inputProps={{ 'data-testid': TESTIDS.form.workoutRounds }}
                />
              );
            }}
          />
        </Grid>

        {/* Workout notes */}
        <Grid item xs={12} md={12}>
          <Box display="flex" width={1}>
            <Controller
              name="notes"
              control={control}
              render={({ field: { ...field } }) => {
                return (
                  <TextField
                    {...field}
                    label="Note"
                    multiline
                    helperText="You can use markdown here"
                    maxRows={4}
                    minRows={4}
                    sx={{ width: 1 / 2 }}
                    inputProps={{ 'data-testid': TESTIDS.form.workoutNotes }}
                  />
                );
              }}
            />
            <Controller
              name="notes"
              control={control}
              render={({ field: { ...field } }) => {
                return (
                  <Box
                    width={1 / 2}
                    height={120}
                    sx={{
                      marginLeft: 2,
                      border: '1px solid black',
                      maxHeight: 120,
                      overflow: 'auto',
                    }}
                    data-testid={TESTIDS.form.workoutNotesPreview}
                  >
                    <Markdown>{field.value}</Markdown>
                  </Box>
                );
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ marginTop: theme.space.s }} />
      <Box mt={3}>
        <Typography component="h3" mb={2} data-testid={TESTIDS.labels.exercise}>
          Add exercise:
        </Typography>

        <Grid container spacing={2}>
          {/* Exercise Search */}
          <Grid item xs={12} md={12}>
            <FormControl sx={{ width: 1 }}>
              <Autocomplete
                disablePortal
                data-testid={TESTIDS.form.exerciseSearch}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={listOfMovements.map((movement) => {
                  return {
                    id: movement.uuid,
                    label: movement.name,
                  };
                })}
                onChange={(_, value) => {
                  const movement = listOfMovements.find(
                    (m) => m.uuid === value?.id,
                  );
                  setSelectedMovementElem(movement);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Movement"
                    //inputProps={{ 'data-testid': TESTIDS.form.exerciseSearch }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          {/* Exercise unit */}
          <Grid item xs={12} md={12}>
            <FormControl sx={{ width: 1 }}>
              <InputLabel id="select-unit">Unit</InputLabel>
              <Select
                labelId="select-unit"
                value={selectedUnit}
                label="Unit"
                onChange={(event: SelectChangeEvent) =>
                  setSelectedUnit(event.target.value as WorkoutMovementUnit)
                }
                inputProps={{ 'data-testid': TESTIDS.form.exerciseUnit }}
              >
                <MenuItem value="total">Total</MenuItem>
                <MenuItem value="time">Time</MenuItem>
                <MenuItem value="weight">Weight</MenuItem>
                <MenuItem value="cals">Cals</MenuItem>
                <MenuItem value="meters">Meters</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Exercise num of reps */}
          <Grid item xs={4} md={4}>
            <TextField
              label="Num of Reps"
              sx={{ width: 1 }}
              value={numOfReps}
              type="number"
              onChange={(event) => {
                const value = parseInt(event.target.value);
                setNumOfReps(isNaN(value) ? undefined : value);
              }}
              inputProps={{ 'data-testid': TESTIDS.form.exerciseReps }}
            />
          </Grid>

          {/* Exercise duration */}
          <Grid item xs={4} md={4}>
            <TextField
              label="Duration"
              sx={{ width: 1 }}
              value={duration}
              type="number"
              onChange={(event) => {
                const value = parseInt(event.target.value);
                setDuration(isNaN(value) ? undefined : value);
              }}
              inputProps={{ 'data-testid': TESTIDS.form.exerciseDuration }}
            />
          </Grid>

          {/* Exercise distance */}
          <Grid item xs={4} md={4}>
            <TextField
              label="Distance"
              sx={{ width: 1 }}
              value={distance}
              type="number"
              onChange={(event) => {
                const value = parseInt(event.target.value);
                setDistance(isNaN(value) ? undefined : value);
              }}
              inputProps={{ 'data-testid': TESTIDS.form.exerciseDistance }}
            />
          </Grid>

          {/* Exercise load man */}
          <Grid item xs={6} md={6}>
            <TextField
              label="Load Man"
              sx={{ width: 1 }}
              value={loadMan}
              type="number"
              onChange={(event) => {
                const value = parseFloat(event.target.value);
                setLoadMan(isNaN(value) ? undefined : value);
              }}
              inputProps={{ 'data-testid': TESTIDS.form.exerciseLoadMan }}
            />
          </Grid>

          {/* Exercise load woman */}
          <Grid item xs={6} md={6}>
            <TextField
              label="Load Woman"
              sx={{ width: 1 }}
              value={loadWoman}
              type="number"
              onChange={(event) => {
                const value = parseFloat(event.target.value);
                setLoadWoman(isNaN(value) ? undefined : value);
              }}
              inputProps={{ 'data-testid': TESTIDS.form.exerciseLoadWoman }}
            />
          </Grid>

          {/* Exercise btn add */}
          <Grid item xs={12} md={12}>
            <Box display="flex" flexDirection="column">
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddNewMovement}
                data-testid={TESTIDS.form.btn.execise}
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box mt={3}></Box>
      </Box>

      <Box mt={2}>
        <MovementsTable
          workoutMovements={workoutMovement}
          onChangeMovementField={handleChangeMovementField}
          onDelete={handleOnDeleteMovement}
          onSwap={handleOnSwapMovement}
        />
      </Box>

      <Box mt={2}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={!isSaveEnabled}
          data-testid={TESTIDS.form.btn.save}
        >
          Save
        </Button>
      </Box>
    </form>
  );
};

WorkoutBuilder.TESTIDS = TESTIDS;
