import { Card, CardMedia, CardContent } from '@mui/material';
import { useResponsive } from '@hooks/useMedia';

type Props = {
  image?: string;
  minheight?: string;
  children: React.ReactNode;
};

const TESTIDS = {
  wrapper: 'wrapper-general-card',
  mediaCard: {
    wrapper: 'wrapper-card-media',
    content: 'content',
  },
};

export const GeneralCard = ({ image, minheight = '240', children }: Props) => {
  const isMobile = useResponsive('down', 'sm');

  return (
    <Card
      data-testid={TESTIDS.wrapper}
      sx={{
        width: isMobile ? 290 : 600,
        maxWidth: '100%',
        borderRadius: '12px',
        padding: 1.5,
        boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
      }}
    >
      {image ? (
        <CardMedia
          data-testid={TESTIDS.mediaCard.wrapper}
          image={image}
          sx={{
            borderRadius: '6px',
            width: '100%',
            height: 0,
            paddingBottom: `min(75%, ${minheight}px)`,
            backgroundColor: 'white',
          }}
        />
      ) : null}
      <CardContent data-testid={TESTIDS.mediaCard.content}>
        {children}
      </CardContent>
    </Card>
  );
};

GeneralCard.TESTIDS = TESTIDS;
