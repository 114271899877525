const convertSecondsToMinutes = (seconds?: number): string => {
  if (seconds === undefined)
    throw new Error('Workout with undefined durantion.');
  if (seconds < 0) throw new Error('Negative seconds are not accepted.');
  if (seconds < 60) return `${seconds} sec`;

  return `${seconds / 60} min`;
};

export const TimeUtils = {
  convertSecondsToMinutes,
};
