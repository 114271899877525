import { GoogleSignInIcon } from '@images/.';

type Props = {
  onClick: () => void;
};

export const GoogleSignIn = ({ onClick }: Props) => {
  return (
    <button onClick={onClick} className="gsi-material-button">
      <div className="gsi-material-button-state" />
      <div className="gsi-material-button-content-wrapper">
        <div className="gsi-material-button-icon">
          <GoogleSignInIcon />
        </div>
        <div className="gsi-material-button-contents">Sign in with Google</div>
      </div>
    </button>
  );
};
