import { InfoMessage } from '../components/InfoMessage';
import { auth as AuthImage } from '../assets';
import { useLogin } from '@hooks/useLogin';

export interface Props {
  children?: React.ReactElement;
}

export const withProtection = <P extends Props>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<P> => {
  const Component = (props: P) => {
    const { isLogin, isLoading } = useLogin();

    if (isLoading) return null;

    return !isLogin ? (
      <InfoMessage
        image={AuthImage}
        message="Please login to access this page!"
      />
    ) : (
      <WrappedComponent {...(props as P)} />
    );
  };

  return Component;
};
