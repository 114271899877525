import { useResponsive } from '@hooks/useMedia';
import { Box, Divider, Typography } from '@mui/material';
import Markdown from 'react-markdown';

type Props = {
  notes: string;
};

const TESTIDS = {
  wrapper: 'wrapper-note',
  note: 'note',
};

export const Notes = ({ notes }: Props) => {
  const isMobile = useResponsive('down', 'sm');

  return (
    <Box mt={2} data-testid={TESTIDS.wrapper}>
      <Divider textAlign="left">
        <Typography component="div" variant="body2">
          <i>Instructions/Notes:</i>
        </Typography>
      </Divider>

      <Markdown
        components={{
          p: ({ children }) => (
            <Typography
              sx={{ mt: 1, pl: isMobile ? 1 : 7 }}
              variant="body2"
              data-testid={TESTIDS.note}
            >
              {children}
            </Typography>
          ),
        }}
      >
        {notes}
      </Markdown>
    </Box>
  );
};

Notes.TESTIDS = TESTIDS;
