import axios from 'axios';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { ApiContext } from '../package/api/context';
import { Headers } from '../util/Headers';
import { ContentsRequest } from '@api/src/thunderwod';

export const useWorkoutAssistant = (token?: string) => {
  const { endpoints } = useContext(ApiContext);

  const { data, isLoading, isSuccess, error, mutate } = useMutation(
    ['workoutAssistant'],
    (contents: ContentsRequest[]) =>
      endpoints.thunderwod.promptAssistant(
        { contents: contents },
        Headers.buildAuthRequestHeaders(token ?? ''),
      ),
  );

  useEffect(() => {
    if (error && axios.isAxiosError(error)) {
      toast.error(
        `Server error message:\n${error.response?.data?.response?.message}`,
      );
    }
  }, [error]);

  return { response: data?.data, isSuccess, isLoading, error, prompt: mutate };
};
