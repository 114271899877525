const buildAuthRequestHeaders = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const Headers = {
  buildAuthRequestHeaders,
};
