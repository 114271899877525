import axios from 'axios';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { ApiContext } from '../package/api/context';
import { WorkoutCreatorRequest } from '../package/api/src/thunderwod';
import { Headers } from '../util/Headers';

export const useWorkout = (token?: string, uuid?: string) => {
  const { endpoints } = useContext(ApiContext);

  const headers = Headers.buildAuthRequestHeaders(token ?? '');

  const { data, isLoading, error } = useQuery(
    ['getWorkout', uuid],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => endpoints.thunderwod.getWorkout(uuid!, headers),
    { enabled: !!token && !!uuid },
  );

  const {
    mutate: createWorkout,
    data: createWorkoutData,
    isSuccess: createWorkoutSuccess,
    error: createWorkoutError,
  } = useMutation(['createWorkout'], (workout: WorkoutCreatorRequest) =>
    endpoints.thunderwod.createWorkout(workout, headers),
  );

  const {
    mutate: deleteWorkout,
    data: deleteWorkoutData,
    isSuccess: deleteWorkoutSucces,
    error: deleteWorkoutError,
  } = useMutation(['deleteWorkout'], (workoutId: string) =>
    endpoints.thunderwod.deleteWorkout(workoutId, headers),
  );

  useEffect(() => {
    if (createWorkoutError && axios.isAxiosError(createWorkoutError)) {
      toast.error(
        `Server error message:\n${createWorkoutError.response?.data?.response?.message}`,
      );
    }

    if (deleteWorkoutError && axios.isAxiosError(deleteWorkoutError)) {
      toast.error(
        `Server error message:\n${deleteWorkoutError.response?.data?.response?.message}`,
      );
    }

    if (error && axios.isAxiosError(error)) {
      toast.error(
        `Server error message:\n${error.response?.data?.response?.message}`,
      );
    }
  }, [error, createWorkoutError, deleteWorkoutError]);

  useEffect(() => {
    if (createWorkoutData && createWorkoutSuccess) {
      toast.success('Workout created with success!', {
        icon: '👏',
      });
    }
  }, [createWorkoutData]);

  useEffect(() => {
    if (deleteWorkoutSucces) {
      toast.success('Workout deleted with success!', {
        icon: '👏',
      });
    }
  }, [deleteWorkoutSucces]);

  return {
    workout: data?.data.workout,
    isLoading,
    error,
    create: {
      mutate: createWorkout,
      workout: createWorkoutData,
    },
    delete: {
      mutate: deleteWorkout,
      workout: deleteWorkoutData,
      isSuccess: deleteWorkoutSucces,
    },
  };
};
