import { ApiContext } from '@api/context';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { Headers } from '../util/Headers';

export const useWorkouts = (
  currentPage?: number,
  sizePerPage = 10,
  enable = false,
  token?: string,
) => {
  const { endpoints } = useContext(ApiContext);

  const { data, isLoading, error, refetch } = useQuery(
    ['getWorkouts', currentPage, sizePerPage],
    () =>
      endpoints.thunderwod.getWorkouts(
        currentPage,
        sizePerPage,
        Headers.buildAuthRequestHeaders(token ?? ''),
      ),
    { enabled: !!token && enable },
  );

  return {
    workouts: data ? data.data.workouts : [],
    pagination: {
      currentPage: data?.data?.metadata?.currentPage,
      lastPage: data?.data?.metadata?.lastPage,
      sizePerPage: data?.data?.metadata?.sizePerPage,
      totalWorkouts: data?.data?.metadata?.totalOfElem,
    },
    isLoading,
    error,
    refetch,
  };
};
