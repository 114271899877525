import { Box } from '@mui/material';

type Props = {
  children: React.ReactNode;
};

const TESTIDS = {
  wrapper: 'wrapper-main-content',
};

export const MainContent = ({ children }: Props) => {
  return (
    <Box
      data-testid={TESTIDS.wrapper}
      display="block"
      alignItems="center"
      justifyContent="center"
      mx={2}
      mt={-5}
    >
      {children}
    </Box>
  );
};

MainContent.TESTIDS = TESTIDS;
