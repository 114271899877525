const baseUriThunderwod =
  process.env.REACT_APP_PROFILE === 'dev'
    ? `${process.env.REACT_APP_HTTP}://${process.env.REACT_APP_THUNDERWOD_API_HOST}:${process.env.REACT_APP_THUNDERWOD_API_PORT}`
    : `${process.env.REACT_APP_HTTPS}://${process.env.REACT_APP_THUNDERWOD_API_HOST_PROD}`;

const customerStripeBillingPortal =
  process.env.REACT_APP_PROFILE === 'dev'
    ? process.env.REACT_APP_STRIPE_BILLING_PORTAL
    : process.env.REACT_APP_STRIPE_BILLING_PORTAL_PROD;

export const Uri = {
  baseUri:
    process.env.REACT_APP_PROFILE === 'dev'
      ? 'http://localhost:3001'
      : 'https://thunder-wod.com',
  baseUriApp: baseUriThunderwod,
  customerStripeBillingPortal,
};
