import axios from 'axios';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { ApiContext } from '../package/api/context';
import { Headers } from '../util/Headers';

export const useApproveWorkout = (token?: string) => {
  const { endpoints } = useContext(ApiContext);

  const { data, isLoading, isSuccess, error, mutate } = useMutation(
    ['approveWorkouts'],
    (id: string) =>
      endpoints.thunderwod.approveWorkout(
        { workoutIds: [id] },
        Headers.buildAuthRequestHeaders(token ?? ''),
      ),
  );

  useEffect(() => {
    if (error && axios.isAxiosError(error)) {
      toast.error(
        `Server error message:\n${error.response?.data?.response?.message}`,
      );
    }
  }, [error]);

  useEffect(() => {
    if (data?.data.workoutIds && isSuccess) {
      toast.success('Workout approved!', {
        icon: '👏',
      });
    }
  }, [data, isSuccess]);

  return { isSuccess, isLoading, error, approve: mutate };
};
