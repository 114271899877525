import { Box, CircularProgress } from '@mui/material';

type Props = {
  size?: number;
  color: string;
};

const TESTIDS = {
  wrapper: 'wrapper-loading',
  circularProgress: 'circular-progress',
};

export const Loading = ({ size = 40, color }: Props) => (
  <Box
    data-testid={TESTIDS.wrapper}
    sx={{
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <CircularProgress
      data-testid={TESTIDS.circularProgress}
      size={size}
      sx={{ color }}
    />
  </Box>
);

Loading.TESTIDS = TESTIDS;
