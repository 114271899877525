import { UserDetailsRequest } from '@api/src/thunderwod';
import { ApiContext } from '../package/api/context';
import { useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Headers } from '../util/Headers';
import toast from 'react-hot-toast';
import axios from 'axios';

export const useMutateUserDetails = (token?: string) => {
  const { endpoints } = useContext(ApiContext);

  const { data, isSuccess, isLoading, error, mutate } = useMutation(
    ['updateUserDetails'],
    (request: UserDetailsRequest) =>
      endpoints.thunderwod.updateUserDetails(
        request,
        Headers.buildAuthRequestHeaders(token ?? ''),
      ),
  );

  useEffect(() => {
    if (data && isSuccess) {
      toast.success('Account updated with success!', {
        icon: '👏',
      });
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (error && axios.isAxiosError(error)) {
      toast.error(
        `Server error message:\n${error.response?.data?.response?.message}`,
      );
    }
  }, [error]);

  return { userDetails: data?.data, isLoading, error, mutate };
};
