import { InfoMessage } from '../components/InfoMessage';
import { MainContent } from '../layout/components';
import { underConstruction as UnderConstructionImage } from '../assets';

const TESTIDS = {
  wrapper: 'wrappper-about-page',
  infoMessage: InfoMessage.TESTIDS,
};

export const AboutPage = () => {
  return (
    <MainContent>
      <InfoMessage
        image={UnderConstructionImage}
        message="We are curently working on this page and will launch soon!"
      />
    </MainContent>
  );
};

AboutPage.TESTIDS = TESTIDS;
