import { Box, Link, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { WorkoutPreview, WorkoutType } from 'src/types';
import { v4 as uuidv4 } from 'uuid';
import {
  workout1,
  workout2,
  workout3,
  workout4,
  workout5,
  workout6,
} from '../../assets';
import { GeneralCard } from '../../layout/components';
import {
  WorkoutElem,
  WorkoutElemMovement,
} from '../../package/api/src/thunderwod';
import { ExerciseUtils } from '../../util/ExerciseUtils';
import { TimeUtils } from '../../util/TimeUtils';
import { ExerciseAerobic } from './Exercise/Aerobic';
import { ExerciseInfo } from './Exercise/Info';
import { ExerciseWeight } from './Exercise/Weight';
import { Notes } from './Notes';

export type Error = {
  show: boolean;
  message?: string;
};

type Props = {
  workout: WorkoutElem | WorkoutPreview;
  isPreviewMode?: boolean;
  captureError?(error: Error): void;
};

const TESTIDS = {
  wrapper: 'wrapper-workout',
  type: {
    amrap: 'amrap',
    emom: 'emom',
    rft: 'rft',
    tabata: 'tabata',
  },
  metadata: {
    wrapper: 'wrapper-metadata',
    instagram: 'created-by-instagram',
  },
  reportProblem: 'report-problem',
};

export const Workout = ({
  workout,
  isPreviewMode = false,
  captureError = () => null,
}: Props) => {
  const theme = useTheme();

  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.random() * 6) + 1,
  );

  useEffect(() => {
    if (!isPreviewMode) {
      setRandomNumber(Math.floor(Math.random() * 6) + 1);
    }
  }, [workout, isPreviewMode]);

  const getRandomImage = () => {
    switch (randomNumber) {
      case 1:
        return workout1;
      case 2:
        return workout2;
      case 3:
        return workout3;
      case 4:
        return workout4;
      case 5:
        return workout5;
      case 6:
      default:
        return workout6;
    }
  };

  const getTotal = useCallback(
    (movement: WorkoutElemMovement): number => {
      switch (movement.unit) {
        case 'cals':
        case 'meters':
          return movement.distance ?? 0;
        case 'time':
          return movement.duration ?? 0;
        case 'total':
          return movement.numOfReps ? parseInt(movement.numOfReps) : 0;
        default:
          return 0;
      }
    },
    [workout],
  );

  const buildExercise = useCallback(
    (
      exercise: WorkoutElemMovement,
      workoutType: WorkoutType,
    ): React.ReactElement => {
      let message = `Problem building exercise: ${JSON.stringify(exercise)}`;

      if (workoutType === 'TABATA') {
        return (
          <ExerciseInfo
            key={uuidv4()}
            name={exercise.name}
            weightMan={exercise.loadMan}
            weightWoman={exercise.loadWoman}
            scale={ExerciseUtils.getScale(exercise)}
          />
        );
      }

      if (ExerciseUtils.isWeightedExercise(exercise)) {
        if (!exercise.numOfReps) {
          message = `Problem building exercise, missing numOfReps: ${JSON.stringify(
            exercise,
          )}`;
        } else {
          return (
            <ExerciseWeight
              key={uuidv4()}
              name={exercise.name}
              weightMan={exercise.loadMan}
              weightWoman={exercise.loadWoman}
              numberOfReps={parseInt(exercise.numOfReps)}
            />
          );
        }
      }

      if (ExerciseUtils.isAerobicExercise(exercise)) {
        return (
          <ExerciseAerobic
            key={uuidv4()}
            name={exercise.name}
            total={getTotal(exercise)}
            scale={ExerciseUtils.getScale(exercise)}
          />
        );
      }

      if (!isPreviewMode) {
        throw new Error(message);
      } else {
        captureError({ show: true, message });
        return <></>;
      }
    },
    [workout],
  );

  const randomImage = getRandomImage();

  const instagramProfile =
    (workout as WorkoutElem).metadata?.createdByInstagram ?? undefined;

  // TODO: Implement LADDER and CHIPPER
  return (
    <GeneralCard image={randomImage}>
      <Box
        data-testid={TESTIDS.wrapper}
        sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}
      >
        {workout?.type === 'AMRAP' && (
          <Typography
            component="div"
            variant="body1"
            data-testid={TESTIDS.type.amrap}
          >
            {`Do as many rounds as possible in ${TimeUtils.convertSecondsToMinutes(
              workout.duration,
            )}:`}
          </Typography>
        )}
        {workout?.type === 'RFT' && (
          <Typography
            component="div"
            variant="body1"
            data-testid={TESTIDS.type.rft}
          >
            {`${workout.numOfRounds} rounds for time of:`}
          </Typography>
        )}
        {workout?.type === 'EMOM' && (
          <Typography
            component="div"
            variant="body1"
            data-testid={TESTIDS.type.emom}
          >
            {`Every ${TimeUtils.convertSecondsToMinutes(
              workout.duration,
            )} for ${workout.numOfRounds} mins:`}
          </Typography>
        )}
        {workout?.type === 'TABATA' && (
          <Typography
            component="div"
            variant="body1"
            data-testid={TESTIDS.type.tabata}
          >
            {`${workout.numOfRounds} rounds (20 secs all effort, 10 secs of rest) of:`}
          </Typography>
        )}
        {workout?.movementsRx.map((exercise) =>
          buildExercise(exercise, workout.type as WorkoutType),
        )}
      </Box>
      {workout?.notes && <Notes notes={workout.notes} />}
      {instagramProfile ? (
        <Box mt={4} data-testid={TESTIDS.metadata.wrapper}>
          <Typography
            component="div"
            variant="body1"
            align="center"
            data-testid={TESTIDS.metadata.instagram}
          >
            created by{' '}
            <Link
              href={`https://www.instagram.com/${instagramProfile}`}
              target="_blank"
            >
              @{instagramProfile}
            </Link>
          </Typography>
        </Box>
      ) : null}
      <Typography
        component="div"
        variant="caption"
        data-testid={TESTIDS.reportProblem}
        sx={{
          textAlign: 'center',
          paddingTop: theme.space.l,
        }}
      >
        Having a problem...please report{' '}
        <Link href="mailto:nbento.neves@gmail.com">here</Link>.
      </Typography>
    </GeneralCard>
  );
};

Workout.TESTIDS = TESTIDS;
