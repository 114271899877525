import { Box, Typography, useTheme } from '@mui/material';

type Props = {
  children: React.ReactNode;
};

const TESTIDS = {
  wrapper: 'footer-wrapper',
  content: 'footer-content',
};

export const Footer = ({ children }: Props) => {
  const theme = useTheme();

  return (
    <Box data-testid={TESTIDS.wrapper} sx={{ marginY: 5, marginX: 3 }}>
      <Typography
        data-testid={TESTIDS.content}
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ fontSize: theme.typography.pxToRem(12) }}
      >
        {children}
      </Typography>
    </Box>
  );
};

Footer.TESTIDS = TESTIDS;
