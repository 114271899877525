import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, styled, Typography } from '@mui/material';
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import {
  MIN_HIGHT_HEADER,
  MIN_HIGHT_LARGE_HEADER,
  NAV_WIDTH,
} from '../shared/constants';

type Props = {
  handleDrawerToggle: () => void;
};

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
  backgroundColor: theme.palette.primary.main,
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  minHeight: MIN_HIGHT_HEADER,
  [theme.breakpoints.up('lg')]: {
    minHeight: MIN_HIGHT_LARGE_HEADER,
    padding: theme.spacing(0, 5),
  },
}));

const TESTIDS = {
  wrapper: 'header-wrapper',
  title: 'header-title',
  drawerToggle: 'header-drawer-toggle',
};

export const Header = ({ handleDrawerToggle }: Props) => {
  return (
    <AppBar data-testid={TESTIDS.wrapper}>
      <Toolbar>
        <IconButton
          data-testid={TESTIDS.drawerToggle}
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          sx={{
            mr: 1,
            color: 'white',
            display: { lg: 'none' },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          data-testid={TESTIDS.title}
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          ThunderWOD
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

Header.TESTIDS = TESTIDS;
