import { useLogin } from '@hooks/useLogin';
import { Box } from '@mui/material';
import { AssistantChat } from '../components/AssistantChat';
import { Subscription } from '../components/Subscription';
import { MainContent } from '../layout/components';

const TESTIDS = {
  wrapper: 'wrappper-workout-assistant-page',
  workoutAssistant: AssistantChat.TESTIDS,
  subscription: Subscription.TESTIDS,
};

export const WorkoutAssisantPage = () => {
  const { isMember, userDetails } = useLogin();

  const customerEmail = userDetails?.email;

  if (!customerEmail) return null;

  return (
    <MainContent>
      <Box width={1} data-testid={TESTIDS.wrapper}>
        {isMember ? (
          <AssistantChat />
        ) : (
          <Subscription customerEmail={customerEmail} />
        )}
      </Box>
    </MainContent>
  );
};

WorkoutAssisantPage.TESTIDS = TESTIDS;
