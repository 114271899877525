import { Button, Grid, TextField } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

const TESTIDS = {
  wrapper: 'wrapper-account-form',
  form: {
    inputEmail: 'input-email',
    inputFirstName: 'input-firstName',
    inputLastName: 'input-lastName',
    inputInstagram: 'input-instagram',
    btnSave: 'btn-save',
  },
};

export type Inputs = {
  email: string;
  instagram?: string;
  firstName: string;
  lastName: string;
};

interface Props {
  defaultValues?: Inputs;
  onSave: (data: Inputs) => void;
}

export const AccountForm = ({ defaultValues, onSave }: Props) => {
  const { handleSubmit, control } = useForm<Inputs>({
    defaultValues: {
      email: defaultValues?.email,
      instagram: defaultValues?.instagram,
      firstName: defaultValues?.firstName,
      lastName: defaultValues?.lastName,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => onSave(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid={TESTIDS.wrapper}>
      <Grid container spacing={2} p={3}>
        <Grid item xs={12} md={12}>
          <Controller
            name="email"
            control={control}
            render={({ field: { ...field } }) => {
              return (
                <TextField
                  {...field}
                  label="Email"
                  sx={{ width: 1 }}
                  size="small"
                  disabled
                  inputProps={{ 'data-testid': TESTIDS.form.inputEmail }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="firstName"
            control={control}
            render={({ field: { ...field } }) => {
              return (
                <TextField
                  {...field}
                  label="First Name"
                  sx={{ width: 1 }}
                  size="small"
                  disabled
                  inputProps={{ 'data-testid': TESTIDS.form.inputFirstName }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="lastName"
            control={control}
            render={({ field: { ...field } }) => {
              return (
                <TextField
                  {...field}
                  label="Last Name"
                  sx={{ width: 1 }}
                  size="small"
                  disabled
                  inputProps={{ 'data-testid': TESTIDS.form.inputLastName }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="instagram"
            control={control}
            render={({ field: { ...field } }) => {
              return (
                <TextField
                  {...field}
                  label="Instagram"
                  sx={{ width: 1 }}
                  size="small"
                  inputProps={{ 'data-testid': TESTIDS.form.inputInstagram }}
                />
              );
            }}
          />
        </Grid>
        <Grid item textAlign="right" width={1}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            data-testid={TESTIDS.form.btnSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

AccountForm.TESTIDS = TESTIDS;
