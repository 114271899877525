import globalAxios, { AxiosInstance } from 'axios';
import {
  Configuration,
  UserControllerApiFactory,
  WorkoutGeneratorControllerApiFactory,
  WorkoutControllerApiFactory,
  MovementsControllerApiFactory,
  SettingsControllerApiFactory,
  AuthenticationControllerApiFactory,
  WorkoutAssistantControllerApiFactory,
} from './src/thunderwod';
import instance from './api-common';
import React from 'react';

export type Api = ReturnType<typeof createApi>;

export type CreateApiSources = {
  thunderwod: {
    config?: Configuration;
    uri: string;
  };
};

export const createApi = (
  sources: CreateApiSources,
  axios: AxiosInstance = instance,
) => {
  const { config: thunderwodConfig, uri: thunderwodUri } = sources.thunderwod;

  return {
    endpoints: {
      thunderwod: {
        ...SettingsControllerApiFactory(thunderwodConfig, thunderwodUri, axios),
        ...WorkoutGeneratorControllerApiFactory(
          thunderwodConfig,
          thunderwodUri,
          axios,
        ),
        ...AuthenticationControllerApiFactory(
          thunderwodConfig,
          thunderwodUri,
          axios,
        ),
        ...MovementsControllerApiFactory(
          thunderwodConfig,
          thunderwodUri,
          axios,
        ),
        ...WorkoutAssistantControllerApiFactory(
          thunderwodConfig,
          thunderwodUri,
          axios,
        ),
        ...WorkoutControllerApiFactory(thunderwodConfig, thunderwodUri, axios),
        ...UserControllerApiFactory(thunderwodConfig, thunderwodUri, axios),
      },
    },
    CancelToken: globalAxios.CancelToken,
  };
};

export const ApiContext = React.createContext<Api>({} as Api);
