import { Headers } from '../util/Headers';
import { ApiContext } from '../package/api/context';
import { EquipmentElem } from '../package/api/src/thunderwod';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

export const useEquipments = (token?: string) => {
  const { endpoints } = useContext(ApiContext);

  const { data, isLoading, error, refetch } = useQuery(
    ['token', token],
    () =>
      endpoints.thunderwod.getEquipments(
        Headers.buildAuthRequestHeaders(token ?? ''),
      ),
    { enabled: !!token },
  );

  const [equipments, setEquipments] = useState<EquipmentElem[] | []>();

  useEffect(() => {
    if (!error && data) {
      setEquipments(data.data.equipment);
    }
  }, [data]);

  return { equipments, isLoading, error, refetch };
};
