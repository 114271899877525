import { Box, Toolbar } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';
import { useResponsive } from '../hooks/useMedia';
import { MainDrawer } from './Drawer';
import { Footer } from './Footer';
import { Header } from './Header';
import { MIN_HIGHT_HEADER, MIN_HIGHT_LARGE_HEADER } from './shared/constants';

const today = new Date();

const TESTIDS = {
  wrapper: 'layout-wrapper',
  header: Header.TESTIDS,
  drawer: MainDrawer.TESTIDS,
  footer: Footer.TESTIDS,
};

export const Layout = () => {
  const [open, setOpen] = useState(false);

  const isDesktop = useResponsive('up', 'lg');

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box data-testid={TESTIDS.wrapper} sx={{ display: 'flex' }}>
      <Header handleDrawerToggle={handleDrawerToggle} />
      <MainDrawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100%',
          overflow: 'auto',
          mt: isDesktop
            ? `${MIN_HIGHT_LARGE_HEADER}px`
            : `${MIN_HIGHT_HEADER}px`,
        }}
      >
        <Toolbar />
        <Outlet />
        <Footer>
          Designed by ©{' '}
          <Link color="inherit" to="/">
            ThunderWOD
          </Link>{' '}
          {today.getFullYear()}
          {' - '}
          All right reserved
        </Footer>
      </Box>
    </Box>
  );
};

Layout.TESTIDS = TESTIDS;
