import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import { Uri } from '../../util/Uri';

type Props = {
  title: string;
  description: string;
  children: React.ReactElement;
  keywords?: string;
  canonicalTag?: string;
};

export const Seo = ({
  title,
  description,
  canonicalTag,
  keywords = 'thunderwod, crossfit, wod, free workout, levels fitness app, cross training, cross-training',
  children,
}: Props) => {
  const location = useLocation();

  const canonical = canonicalTag ?? Uri.baseUri + location.pathname;

  return (
    <>
      <Helmet>
        {/* Standard metadata tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="msapplication-TileColor" content="#2d89ef" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="keywords" content={keywords}></meta>
        {/* End standard metadata tags */}

        <link rel="canonical" href={canonical} />
      </Helmet>
      {children}
    </>
  );
};
