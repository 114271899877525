import { WorkoutElem } from '@api/src/thunderwod';
import { useMutateWorkout } from '@hooks/useMutateWorkout';
import { useQueryWorkoutGenerator } from '@hooks/useQueryWorkoutGenerator';
import {
  History as HistoryIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { Box, Button, Grid, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { http404 as NotFoundImage } from '../assets';
import { InfoMessage } from '../components/InfoMessage';
import { Workout } from '../components/Workout';
import { Loading, MainContent } from '../layout/components';
import { Seo } from '../components/Seo';
import { SeoUtils } from '../util/SeoUtils';
import { WorkoutType } from '../types';

const TESTIDS = {
  wrapper: 'workout-wrapper',
  workout: 'workout-content',
  tryAgain: 'try-again-content',
  loading: 'loading',
  previousBtn: 'previous-button',
  reloadBtn: 'reload-button',
};

export const WorkoutPage = () => {
  const theme = useTheme();
  const { type } = useParams();
  const { state } = useLocation();

  const workoutType = type as WorkoutType;
  const workoutEquipments = state?.workoutEquipments;

  const [enablePreviousWorkout, setEnablePreviousWorkout] =
    useState<boolean>(false);
  const [previousWorkoutId, setPreviousWorkoutId] = useState<
    string | undefined
  >(undefined);

  const [workout, setWorkout] = useState<WorkoutElem>();

  const {
    workout: generatedWorkout,
    isLoading,
    error,
    refetch,
  } = useQueryWorkoutGenerator(workoutType, workoutEquipments);

  const { workout: previousWorkout, fetch: fetchWorkout } =
    useMutateWorkout(previousWorkoutId);

  useEffect(() => {
    if (generatedWorkout) {
      setWorkout(generatedWorkout);
    }
  }, [generatedWorkout]);

  useEffect(() => {
    if (previousWorkout) {
      setWorkout(previousWorkout);
    }
  }, [previousWorkout]);

  const seoInfo = SeoUtils.getSeoInfoBasedWorkoutType(workoutType);

  return (
    <Seo
      title={seoInfo.title}
      description={seoInfo.description}
      keywords={seoInfo.tags}
    >
      <MainContent>
        {isLoading ? (
          <Box data-testid={TESTIDS.loading}>
            <Loading size={200} color={theme.palette.secondary.main} />
          </Box>
        ) : (
          <Grid
            data-testid={TESTIDS.wrapper}
            spacing={theme.space.l}
            container
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {workout && (
              <Grid item data-testid={TESTIDS.workout}>
                <Workout workout={workout} />
              </Grid>
            )}
            {error ? (
              <Grid item data-testid={TESTIDS.tryAgain}>
                <InfoMessage
                  image={NotFoundImage}
                  message="We could not find any workout for you, please try again later!"
                />
              </Grid>
            ) : null}
            <Grid item>
              <Button
                variant="outlined"
                data-testid={TESTIDS.previousBtn}
                disabled={!enablePreviousWorkout}
                onClick={() => {
                  setEnablePreviousWorkout(false);
                  fetchWorkout();
                }}
                startIcon={<HistoryIcon sx={{ color: 'black' }} />}
                sx={{
                  mx: 1,
                }}
              >
                Previous
              </Button>

              <Button
                variant="outlined"
                data-testid={TESTIDS.reloadBtn}
                onClick={() => {
                  setPreviousWorkoutId(workout?.id);
                  setEnablePreviousWorkout(true);
                  refetch();
                }}
                endIcon={<RefreshIcon sx={{ color: 'black' }} />}
                sx={{
                  mx: 1,
                }}
              >
                New
              </Button>
            </Grid>
          </Grid>
        )}
      </MainContent>
    </Seo>
  );
};

WorkoutPage.TESTIDS = TESTIDS;
