import {
  WorkoutCreatorRequest,
  WorkoutElem,
  WorkoutElemMovement,
} from '../../../../package/api/src/thunderwod';
import { Inputs } from '..';
import {
  WorkoutMovement,
  WorkoutMovementUnit,
  WorkoutPreview,
} from '../../../../types';

const toApi = (data: Inputs): WorkoutCreatorRequest => {
  const movementsRx = data.movements.map((m) => {
    const movement: WorkoutElemMovement = {
      name: m.name,
      unit: m.unit,
      numOfReps: m.numOfReps ?? '',
      duration: m.duration,
      distance: m.distance,
      loadMan: m.loadMan,
      loadWoman: m.loadWoman,
    };

    return movement;
  });

  return {
    workoutName: data.name,
    workoutDifficulty: data.difficulty.toUpperCase(),
    workoutType: data.type.toUpperCase(),
    workoutDuration: data.duration,
    workoutRounds: data.rounds,
    workoutNote: data.notes,
    workoutMovementsRX: movementsRx,
    workoutMovementsScale: [],
    isHyrox: data.isHyrox === 'true',
  };
};

const toForm = (workout: WorkoutElem): Inputs => {
  const movements = workout.movementsRx.map((m) => {
    const movement: WorkoutMovement = {
      uuid: m.uuid ?? '',
      name: m.name,
      unit: m.unit as WorkoutMovementUnit,
      numOfReps: m.numOfReps,
      duration: m.duration,
      distance: m.distance,
      loadMan: m.loadMan,
      loadWoman: m.loadWoman,
    };

    return movement;
  });

  return {
    name: workout.description,
    isHyrox: `${workout.metadata?.isHyrox}` ?? 'false',
    duration: workout.duration ?? 0,
    rounds: workout.numOfRounds ?? 0,
    type: workout.type.toLowerCase(),
    notes: workout.notes ?? '',
    difficulty: workout.difficulty.toLowerCase(),
    movements: movements,
  };
};

const toWorkoutElem = (data: Inputs): WorkoutPreview => {
  const movementsRx = data.movements.map((m) => {
    const movement: WorkoutElemMovement = {
      name: m.name,
      unit: m.unit,
      numOfReps: m.numOfReps ?? '',
      duration: m.duration,
      distance: m.distance,
      loadMan: m.loadMan,
      loadWoman: m.loadWoman,
    };

    return movement;
  });

  return {
    description: data.name,
    difficulty: data.difficulty.toUpperCase(),
    type: data.type.toUpperCase(),
    duration: data.duration,
    numOfRounds: data.rounds,
    notes: data.notes,
    movementsRx: movementsRx,
    movementsScale: [],
  };
};

export const FormWorkoutTransformer = {
  toApi,
  toForm,
  toWorkoutElem,
};
