import { ToastBar, Toaster } from 'react-hot-toast';
import { InternalRoutes } from './Routes';
import { useTheme } from '@mui/material';

const App = () => {
  const theme = useTheme();

  return (
    <div>
      <Toaster>
        {(t) => (
          <ToastBar
            toast={t}
            style={{
              fontFamily: theme.typography.body2.fontFamily,
            }}
          >
            {({ icon, message }) => (
              <>
                {icon}
                {message}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      <InternalRoutes />
    </div>
  );
};

export default App;
