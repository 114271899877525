import { Typography } from '@mui/material';
import { WorkoutScale } from 'src/types';

type Props = {
  name: string;
  weightMan?: number;
  weightWoman?: number;
  weightScale?: 'kg' | 'lbs';
  scale: WorkoutScale;
};

const TESTIDS = {
  wrapper: 'wrapper-exercise-info',
};

export const ExerciseInfo = ({
  name,
  weightMan,
  weightWoman,
  weightScale = 'kg',
  scale,
}: Props) => {
  const buildText = () => {
    switch (scale) {
      case 'Reps':
      case 'Meters':
      case 'Cal':
      case 'Minutes':
        return `${name}`;
      case 'Weight':
        return `${name} @ ${weightMan}/${weightWoman} ${weightScale}`;
    }
  };

  return (
    <Typography data-testid={TESTIDS.wrapper} component="div" variant="body2">
      {buildText()}
    </Typography>
  );
};

ExerciseInfo.TESTIDS = TESTIDS;
