import { WorkoutElemMovement } from '../package/api/src/thunderwod';
import { WorkoutScale } from 'src/types';

const isWeightedExercise = (exercise: WorkoutElemMovement): boolean => {
  const isRightUnit = exercise.unit === 'weight';

  const hasRightValues =
    !!exercise.loadMan && !!exercise.loadWoman && !!exercise.numOfReps;

  return isRightUnit && hasRightValues;
};

const isAerobicExercise = (exercise: WorkoutElemMovement): boolean => {
  const isCalsExercise = exercise.unit === 'cals' && !!exercise.distance;
  const isMetersExercise = exercise.unit === 'meters' && !!exercise.distance;
  const isTimeExercise = exercise.unit === 'time' && !!exercise.duration;
  const isTotalExercise = exercise.unit === 'total' && !!exercise.numOfReps;

  const hasRightValues = !!exercise.loadMan && !!exercise.loadWoman;

  return (
    (isCalsExercise || isMetersExercise || isTimeExercise || isTotalExercise) &&
    !hasRightValues
  );
};

const getScale = (movement: WorkoutElemMovement): WorkoutScale => {
  if (movement.unit === 'cals') return 'Cal';
  if (movement.unit === 'meters') return 'Meters';
  if (movement.unit === 'time') return 'Minutes';
  if (movement.unit === 'total') return 'Reps';
  if (movement.unit === 'weight') return 'Weight';
  return 'Cal';
};

export const ExerciseUtils = {
  isWeightedExercise,
  isAerobicExercise,
  getScale,
};
