import { useLogin } from '@hooks/useLogin';
import { useWorkoutAssistant } from '@hooks/useWorkoutAssistant';
import { LogoIcon } from '@images/.';
import { Send as SendIcon } from '@mui/icons-material';
import {
  Box,
  Divider,
  Fab,
  Grid,
  LinearProgress,
  List,
  Paper,
  TextField,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Message } from './Message';

const TESTIDS = {
  wrapper: 'wrapper-chat-assistant',
  messages: (index: number) => `message-${index}`,
  loadingAssistantMessage: 'loading-assistant-message',
  messageField: 'message-field',
  sendBtn: 'sendBtn',
};

type ChatMessage = {
  key: number;
  orientention: 'left' | 'right';
  message: string;
  avatar?: string | React.ReactElement;
};

const DEFAULT_MESSAGES = {
  key: 1,
  orientention: 'left',
  message: 'Hey! What type of workout are you looking for?',
  avatar: <LogoIcon />,
} as ChatMessage;

export const AssistantChat = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([DEFAULT_MESSAGES]);

  const { googleToken, userDetails } = useLogin();
  const { response, isLoading, prompt } = useWorkoutAssistant(googleToken);

  const messageRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const onSend = () => {
    if (messageRef?.current) {
      const message = messageRef.current.value;

      setMessages([
        ...messages,
        {
          key: 2,
          orientention: 'right',
          message,
          avatar: userDetails?.picture,
        },
      ]);

      messageRef.current.value = '';

      //Convert internal messages to send to the backend
      const promptMessages = [
        ...messages.map((it) => ({
          role: it.orientention === 'left' ? 'model' : 'user',
          text: it.message,
        })),
        { role: 'user', text: message },
      ];

      prompt(promptMessages);
    }
  };

  useEffect(() => {
    if (response) {
      setMessages([
        ...messages,
        {
          key: 1,
          orientention: 'left',
          message: response.text,
          avatar: <LogoIcon />,
        },
      ]);
    }
  }, [response]);

  useEffect(() => {
    //Automatic scroll the messages
    if (listRef) {
      listRef.current?.lastElementChild?.scrollIntoView();
    }
  }, [messages]);

  return (
    <Paper data-testid={TESTIDS.wrapper}>
      <Grid item>
        <List
          sx={{
            minHeight: 510,
            maxHeight: 500,
            overflow: 'auto',
          }}
          ref={listRef}
        >
          {messages.map((message, index) => (
            <Box key={message.key} data-testid={TESTIDS.messages(index)}>
              <Message
                orientation={message.orientention}
                message={message.message}
                typingEffect={message.orientention === 'left'}
                avatarImg={message.avatar}
              />
            </Box>
          ))}
          {isLoading ? (
            <LinearProgress data-testid={TESTIDS.loadingAssistantMessage} />
          ) : null}
        </List>
        <Divider />
        <Grid container style={{ padding: '20px', width: '100%' }}>
          <Grid item xs={12} display="flex" alignItems="center">
            <TextField
              label=""
              inputRef={messageRef}
              fullWidth
              inputProps={{ 'data-testid': TESTIDS.messageField }}
            />
            <Fab
              size="small"
              color="primary"
              onClick={onSend}
              sx={{ marginLeft: 1 }}
              data-testid={TESTIDS.sendBtn}
            >
              <SendIcon />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

AssistantChat.TESTIDS = TESTIDS;
