import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { GLOBAL } from './constants';
import { globalReducer } from './feature/globalSlice';

const makeRootReducer = combineReducers({
  [GLOBAL]: globalReducer,
});

export const store = configureStore({
  reducer: makeRootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
