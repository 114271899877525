import { Box } from '@mui/material';

const TESTIDS = {
  wrapper: 'wrappper-terms-and-conditions-page',
};

export const TermsConditions = () => {
  return (
    <Box
      height="100vh"
      sx={{
        px: 2,
      }}
      data-testid={TESTIDS.wrapper}
    >
      <iframe
        style={{
          border: 'none',
          width: '100%',
          height: '100%',
        }}
        src="terms-and-conditions.html"
      />
    </Box>
  );
};

TermsConditions.TESTIDS = TESTIDS;
