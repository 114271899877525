import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import { http404 as NotFoundImage } from './assets';
import { InfoMessage } from './components/InfoMessage';
import { Seo } from './components/Seo';
import { withProtection } from './hoc/withProtection';
import { Layout } from './layout';
import { WorkoutPreviewPage } from './pages/WorkoutPreviewPage';
import {
  AccountPage,
  HomePage,
  WorkoutGeneratorPage,
  WorkoutPage,
  WorkoutAssisantPage,
  TermsConditions,
} from './pages/index';
import ErrorFallback from './ErrorFallback';
import { WorkoutBuilderPage } from './pages/WorkoutBuilderPage';

const SettingsPageProtected = withProtection(AccountPage);
const WorkoutGeneratorPageProtected = withProtection(WorkoutGeneratorPage);
const WorkoutPreviewPageProtected = withProtection(WorkoutPreviewPage);
const WorkoutBuilderPageProtected = withProtection(WorkoutBuilderPage);
const WorkoutAssisantPageProtected = withProtection(WorkoutAssisantPage);

const pages = [
  {
    key: 0,
    path: '/',
    index: true,
    element: <HomePage />,
  },
  {
    key: 1,
    path: 'workout/:type',
    index: false,
    element: <WorkoutPage />,
  },
  {
    key: 2,
    path: 'account',
    index: false,
    element: (
      <Seo
        title="ThunderWOD - Check your details account"
        description="The account page allows you to check the details which we know about you. Check, edit or delete your account information."
        keywords="crossfit, wod, levels fitness app, cross training, cross-training, free weight workout routines, best free gym workout apps"
      >
        <SettingsPageProtected />
      </Seo>
    ),
  },
  {
    key: 3,
    path: '*',
    index: false,
    element: (
      <Seo
        title="ThunderWOD - Page not found"
        description="We could not find the page you were looking for! Please visit us later or maybe try a different page."
      >
        <InfoMessage
          image={NotFoundImage}
          message="We could not find the page you were looking for!"
        />
      </Seo>
    ),
  },
  {
    key: 4,
    path: 'workout/filter',
    index: false,
    element: (
      <Seo
        title="ThunderWOD - Choose the right type and equipment to generate the workout"
        description="Easy way to generate a workout with filters. Choose the type and movements you wish and you can get your workout in seconds."
        keywords="crossfit, wod, levels fitness app, cross training, cross-training, free weight workout routines, best free gym workout apps"
      >
        <WorkoutGeneratorPageProtected />
      </Seo>
    ),
  },
  {
    key: 5,
    path: 'workout/preview/:id',
    index: false,
    element: <WorkoutPreviewPageProtected />,
  },
  {
    key: 6,
    path: 'workout/builder',
    index: false,
    element: (
      <Seo
        title="ThunderWOD - Build your workout and share it with everyone!"
        description="This tool helps you to build your own workout and share it with everyone. If you like you can link an Instagram account!"
        keywords="crossfit, wod, levels fitness app, cross training, cross-training, free weight workout routines, best free gym workout apps, build workout, instagram"
      >
        <WorkoutBuilderPageProtected />
      </Seo>
    ),
  },
  {
    key: 7,
    path: 'terms-and-conditions',
    index: false,
    element: (
      <Seo
        title="ThunderWOD - Terms and conditions!"
        description="Check the terms and conditions of ThunderWOD. If you have any concerns please do not hesitate to contact us!"
        keywords="crossfit, wod, levels fitness app, cross training, cross-training, free weight workout routines, best free gym workout apps, build workout, terms and conditions"
      >
        <TermsConditions />
      </Seo>
    ),
  },
  {
    key: 8,
    path: 'workouts/assistant',
    index: false,
    element: (
      <Seo
        title="ThunderWOD - WOD Assistant!"
        description="Harness the power of an advanced AI assistant to optimize your workout for maximum results."
        keywords="crossfit, wod, ai, workout assistant, best free gym workout apps, build workout"
      >
        <WorkoutAssisantPageProtected />
      </Seo>
    ),
  },
];

export const InternalRoutes = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      {pages.map((page) => {
        const ComponentToRender = (
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            //TODO: Add error logging with datadog
            onError={(error) => console.log(error)}
          >
            {page.element}
          </ErrorBoundary>
        );

        return (
          <Route
            key={page.key}
            path={page.path}
            index={page.index}
            element={ComponentToRender}
          />
        );
      })}
    </Route>
  </Routes>
);
