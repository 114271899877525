import { Box } from '@mui/material';
import {
  GeneratorInputs,
  WorkoutGeneratorForm,
} from '../components/Workout/Generator/Form';
import { useLogin } from '@hooks/useLogin';
import { MainContent } from '../layout/components';
import { useEquipments } from '@hooks/useEquipments';
import { useNavigate } from 'react-router-dom';
import { Subscription } from '../components/Subscription';

const TESTIDS = {
  ...WorkoutGeneratorForm.TESTIDS,
  wrapper: 'wrappper-workout-filter-page',
};

//TODO: Write test when member not available
export const WorkoutGeneratorPage = () => {
  const { googleToken, isMember, userDetails } = useLogin();
  const navigate = useNavigate();

  const { equipments } = useEquipments(googleToken);

  if (!equipments || equipments.length === 0) return null;

  const onSubmit = (data: GeneratorInputs) => {
    if (data.workoutType) {
      const workoutType = data.workoutType.toUpperCase();

      const workoutEquipments = data.workoutEquipment.map((equip) =>
        equip.split('_')[1].toUpperCase(),
      );

      navigate(`/workout/${workoutType}`, {
        replace: true,
        state: {
          workoutType,
          workoutEquipments,
        },
      });
    }
  };

  const customerEmail = userDetails?.email;

  if (!customerEmail) return null;

  return (
    <MainContent>
      <Box width={1} data-testid={TESTIDS.wrapper}>
        {isMember ? (
          <WorkoutGeneratorForm equipments={equipments} onSubmit={onSubmit} />
        ) : (
          <Subscription customerEmail={customerEmail} />
        )}
      </Box>
    </MainContent>
  );
};

WorkoutGeneratorPage.TESTIDS = TESTIDS;
