import { Button, Modal, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  open: boolean;
  actionTitle: string;
  actionDescription: string;
  onClose: () => void;
  onConfirmation: () => void;
}

const TESTIDS = {
  wrapper: 'wrapper-confirmation-modal',
  modalActionTitle: 'modal-action-title',
  modalActionDescription: 'modal-action-description',
  action: {
    confirm: 'modal-action-confirm',
    close: 'modal-action-close',
  },
};

export const ConfirmationModal = ({
  open,
  actionTitle,
  actionDescription,
  onClose,
  onConfirmation,
}: Props) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
        data-testid={TESTIDS.wrapper}
      >
        <Typography
          data-testid={TESTIDS.modalActionTitle}
          variant="h6"
          component="h2"
        >
          {actionTitle}
        </Typography>
        <Typography data-testid={TESTIDS.modalActionDescription} sx={{ mt: 2 }}>
          {actionDescription}
        </Typography>
        <Box
          display="flex"
          justifyContent="flex-end"
          width={1}
          sx={{ border: '1px black' }}
          mt={2}
        >
          <Button
            variant="contained"
            onClick={onClose}
            data-testid={TESTIDS.action.close}
          >
            Cancel
          </Button>
          <Button
            sx={{
              marginLeft: theme.space.s,
            }}
            variant="contained"
            onClick={onConfirmation}
            data-testid={TESTIDS.action.confirm}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ConfirmationModal.TESTIDS = TESTIDS;
