import { useResponsive } from '@hooks/useMedia';
import { CardMedia as CardMediaMui } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

type CardContentContentProps = {
  isMobile: boolean;
  color: string;
};

type TypographySubtitleProps = {
  isMobile: boolean;
};

const CardActionAreaActionArea = styled(CardActionArea)(() => ({
  borderRadius: 16,
  transition: '0.2s',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const StyledCard = styled(Card)(({ theme }: { theme: Theme }) => ({
  minWidth: 200,
  borderRadius: 16,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: `0 6px 12px 0 ${theme.palette.primary.main}`,
  },
}));

const StyledCardWithNewBadge = styled(Card)(({ theme }: { theme: Theme }) => ({
  minWidth: 200,
  borderRadius: 16,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: `0 6px 12px 0 ${theme.palette.primary.main}`,
  },
  '&:after': {
    position: 'absolute',
    content: '"NEW"',
    top: '11px',
    left: '-14px',
    padding: '0.5rem',
    background: theme.palette.primary.main,
    color: 'white',
    textAlign: 'center',
  },
}));

const CardContentContent = styled(CardContent)(
  ({ color, isMobile = false }: CardContentContentProps) => ({
    backgroundColor: color,
    paddingTop: isMobile ? '24px' : '15px',
  }),
);

const TypographyTitle = styled(Typography)(
  ({ isMobile = false }: TypographySubtitleProps) => ({
    fontSize: isMobile ? 20 : 32,
    color: '#fff',
    textTransform: 'uppercase',
  }),
);

const TypographySubtitle = styled(Typography)(() => ({
  color: '#fff',
  opacity: 0.87,
  marginTop: '1rem',
  fontWeight: 500,
  fontSize: 14,
}));

type Props = {
  color: string;
  useNewBadge?: boolean;
  image?: string;
  title: string;
  link?: string;
  subtitle?: string;
};

const TESTIDS = {
  wrapper: 'wrapper-solid-card',
  card: {
    link: 'link',
    media: 'media',
    content: {
      wrapper: 'wrapper-content',
      title: 'title',
      subtitle: 'subtitle',
    },
  },
};

export const SolidCard = ({
  color,
  image,
  title,
  link,
  subtitle,
  useNewBadge = false,
}: Props) => {
  const isMobile = useResponsive('down', 'sm');

  const CardMedia = (
    <CardMediaMui
      data-testid={TESTIDS.card.media}
      image={image ?? ''}
      sx={{
        width: '100%',
        height: 0,
        paddingBottom: isMobile ? '0' : '75%',
        backgroundColor: 'rgba(0,0,0,0.08)',
      }}
    />
  );

  const CardContent = (
    <CardContentContent
      color={color}
      isMobile={isMobile}
      data-testid={TESTIDS.card.content.wrapper}
    >
      <TypographyTitle
        data-testid={TESTIDS.card.content.title}
        align="center"
        variant="h3"
        isMobile={isMobile}
      >
        {title}
      </TypographyTitle>
      {subtitle ? (
        <TypographySubtitle data-testid={TESTIDS.card.content.subtitle}>
          {subtitle}
        </TypographySubtitle>
      ) : null}
    </CardContentContent>
  );

  return (
    <CardActionAreaActionArea>
      <StyledCardWrapper color={color} useNewBadge={useNewBadge}>
        {link ? (
          <Link
            data-testid={TESTIDS.card.link}
            style={{
              textDecoration: 'none',
            }}
            to={link}
          >
            {CardMedia}
            {CardContent}
          </Link>
        ) : (
          <>
            {CardMedia}
            {CardContent}
          </>
        )}
      </StyledCardWrapper>
    </CardActionAreaActionArea>
  );
};

SolidCard.TESTIDS = TESTIDS;

interface StyledCardWrapperProps extends Pick<Props, 'useNewBadge' | 'color'> {
  children: React.ReactNode;
}

const StyledCardWrapper = ({
  useNewBadge,
  color,
  children,
}: StyledCardWrapperProps) => {
  if (useNewBadge) {
    return (
      <StyledCardWithNewBadge data-testid={TESTIDS.wrapper} color={color}>
        {children}
      </StyledCardWithNewBadge>
    );
  } else {
    return (
      <StyledCard data-testid={TESTIDS.wrapper} color={color}>
        {children}
      </StyledCard>
    );
  }
};
