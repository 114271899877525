import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GLOBAL } from '../constants';

// Define a type for the slice state
interface State {
  isLogin: boolean;
}

// Define the initial state using that type
const initialState: State = {
  isLogin: false,
};

const slice = createSlice({
  name: GLOBAL,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    isLogin: (state: State) => {
      state.isLogin = true;
    },
    isLogout: (state: State) => {
      state.isLogin = false;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    changeStatusLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
  },
});

export const GlobalSlice = {
  isLogin: slice.actions.isLogin,
  isLogout: slice.actions.isLogout,
  changeStatusLogin: slice.actions.changeStatusLogin,
};

export const { reducer: globalReducer } = slice;

// Other code such as selectors can use the imported `RootState` type
export const useIsEnable = (state: State) => state.isLogin;
