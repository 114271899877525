import { useLogin } from '@hooks/useLogin';
import { useResponsive } from '@hooks/useMedia';
import { useMovements } from '@hooks/useMovements';
import { useWorkout } from '@hooks/useWorkout';
import { Box, Divider, Tab, Tabs, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WorkoutPreview } from 'src/types';
import { http500 as ErrorImage } from '../assets';
import { InfoMessage } from '../components/InfoMessage';
import { Subscription } from '../components/Subscription';
import { Workout } from '../components/Workout';
import { Inputs, WorkoutBuilder } from '../components/Workout/Builder';
import { FormWorkoutTransformer } from '../components/Workout/Builder/utils/transformer';
import { MainContent } from '../layout/components';

type Error = {
  show: boolean;
  message?: string;
};

const TESTIDS = {
  wrapper: 'wrappper-workout-builder-page',
  mobile: {
    tabs: 'workout-builder-mobile-tabs',
    tab1: 'workout-builder-mobile-tabs-1',
    tab2: 'workout-builder-mobile-tabs-2',
    builder: 'workout-builder-mobile-builder',
    preview: 'workout-builder-mobile-preview',
  },
  desktop: {
    view: 'workout-builder-desktop-view',
  },
  builder: 'workout-builder',
  preview: 'workout-preview',
  subscription: Subscription.TESTIDS,
};

export const WorkoutBuilderPage = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const ismobile = useResponsive('down', 'md');
  const { googleToken, isMember, userDetails } = useLogin();

  const uuid = searchParams.get('id') ?? undefined;

  const { movements } = useMovements(googleToken);
  const { workout, create } = useWorkout(googleToken, uuid);

  const [tabIndex, setTabIndex] = useState(0);
  const [error, setError] = useState<Error>({
    show: false,
    message: '',
  });

  const [previewWorkout, setPreviewWorkout] = useState<
    WorkoutPreview | undefined
  >(undefined);

  const onSubmit = (data: Inputs) => {
    const request = FormWorkoutTransformer.toApi(data);

    create.mutate(request);
  };

  const onChange = (data: Inputs) => {
    const workoutElem = FormWorkoutTransformer.toWorkoutElem(data);

    setPreviewWorkout(workoutElem);
  };

  useEffect(() => {
    if (workout) {
      setPreviewWorkout(workout);
      setError({ show: false });
    }
  }, [workout]);

  useEffect(() => {
    if (previewWorkout) {
      setError({ show: false });
    }
  }, [previewWorkout]);

  const isEditMode = searchParams.get('mode') === 'edit';
  const customerEmail = userDetails?.email;
  const defaultValue =
    workout && isEditMode ? FormWorkoutTransformer.toForm(workout) : undefined;

  //FIXME: Implement loading here
  if (!movements) return null;
  if (!customerEmail) return null;

  const workoutBuilder = (
    <Box width={ismobile ? 1 : 1 / 2} data-testid={TESTIDS.builder}>
      <WorkoutBuilder
        listOfMovements={movements}
        defaultValues={defaultValue}
        isSaveEnabled={!error.show}
        onSave={onSubmit}
        onChange={onChange}
      />
    </Box>
  );

  const workoutPreviou = (
    <Box
      display="flex"
      width={ismobile ? 1 : 1 / 2}
      justifyContent="center"
      data-testid={TESTIDS.preview}
    >
      <Box px={2}>
        {!previewWorkout || error?.show ? (
          <InfoMessage
            image={ErrorImage}
            message={
              error.message ??
              'An issue occured while loading this page, please try again later!'
            }
          />
        ) : (
          <Workout
            workout={previewWorkout}
            isPreviewMode
            captureError={(error: Error) => setError(error)}
          />
        )}
      </Box>
    </Box>
  );

  return (
    <MainContent>
      <Box width={1} data-testid={TESTIDS.wrapper}>
        {isMember ? (
          <>
            {ismobile ? (
              <>
                <Box
                  sx={{ borderBottom: 1, borderColor: 'divider' }}
                  data-testid={TESTIDS.mobile.tabs}
                >
                  <Tabs
                    value={tabIndex}
                    onChange={(_: React.SyntheticEvent, newValue: number) =>
                      setTabIndex(newValue)
                    }
                    aria-label="basic tabs example"
                  >
                    <Tab label="Builder" data-testid={TESTIDS.mobile.tab1} />
                    <Tab label="Preview" data-testid={TESTIDS.mobile.tab2} />
                  </Tabs>
                </Box>
                <div
                  role="tabpanel"
                  hidden={tabIndex !== 0}
                  style={{ marginTop: theme.space.s }}
                  data-testid={TESTIDS.mobile.builder}
                >
                  {workoutBuilder}
                </div>
                <div
                  role="tabpanel"
                  hidden={tabIndex !== 1}
                  style={{ marginTop: theme.space.s }}
                  data-testid={TESTIDS.mobile.preview}
                >
                  {workoutPreviou}
                </div>
              </>
            ) : (
              <Box display="flex" data-testid={TESTIDS.desktop.view}>
                {workoutBuilder}
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    marginX: theme.space.xl,
                  }}
                />
                {workoutPreviou}
              </Box>
            )}
          </>
        ) : (
          <Subscription customerEmail={customerEmail} />
        )}
      </Box>
    </MainContent>
  );
};

WorkoutBuilderPage.TESTIDS = TESTIDS;
