import { useContext } from 'react';
import { useQuery } from 'react-query';
import { ApiContext } from '../package/api/context';
import { Headers } from '../util/Headers';

export const useQueryWorkout = (id?: string, token?: string) => {
  const { endpoints } = useContext(ApiContext);

  const { data, isLoading, error, refetch } = useQuery(
    ['workoutId', id],
    () =>
      endpoints.thunderwod.getWorkout(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id!,
        Headers.buildAuthRequestHeaders(token ?? ''),
      ),
    {
      enabled: !!id && !!token,
    },
  );

  return { workout: data?.data.workout, isLoading, error, refetch };
};
