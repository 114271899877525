import { useEffect, useState } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useLocation } from 'react-router';
import { http500 as ErrorImage } from './assets';
import { InfoMessage } from './components/InfoMessage';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const location = useLocation();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(true);
  }, [error]);

  useEffect(() => {
    if (isError) {
      resetErrorBoundary();
      setIsError(false);
    }
  }, [location.pathname, error]);

  return (
    <InfoMessage
      image={ErrorImage}
      message="An issue occured while loading this page, please try again later!"
    />
  );
};

export default ErrorFallback;
