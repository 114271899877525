import { Grid, Typography } from '@mui/material';
import { useResponsive } from '@hooks/useMedia';

type Props = {
  message: string;
  image: string;
};

const TESTIDS = {
  wrapper: 'wrappper-info-message',
};

export const InfoMessage = ({ message, image }: Props) => {
  const isDesktop = useResponsive('up', 'md');

  return (
    <Grid
      data-testid={TESTIDS.wrapper}
      container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        textAlign: 'center',
      }}
    >
      <Grid item>
        <img
          src={image}
          style={{
            width: !isDesktop ? '80%' : '600px',
            height: '100%',
          }}
        />
      </Grid>
      <Grid
        item
        sx={{
          marginTop: 2,
          paddingX: 5,
        }}
      >
        <Typography component="p">{message}</Typography>
      </Grid>
    </Grid>
  );
};

InfoMessage.TESTIDS = TESTIDS;
