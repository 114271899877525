import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

export const useResponsive = (
  query: 'up' | 'down' | 'between' | 'only',
  start: Breakpoint,
  end?: Breakpoint,
): boolean => {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(start));

  const mediaDown = useMediaQuery(theme.breakpoints.down(start));

  const mediaBetween =
    (end && useMediaQuery(theme.breakpoints.between(start, end))) || false;

  const mediaOnly = useMediaQuery(theme.breakpoints.only(start));

  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }

  return mediaOnly;
};
