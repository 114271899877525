import { Headers } from '../util/Headers';
import { ApiContext } from '../package/api/context';
import { MovementElem } from '../package/api/src/thunderwod';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

export const useMovements = (token?: string) => {
  const { endpoints } = useContext(ApiContext);

  const { data, isLoading, error } = useQuery(
    ['getMovements'],
    () =>
      endpoints.thunderwod.getAllMovements(
        Headers.buildAuthRequestHeaders(token ?? ''),
      ),
    { enabled: !!token },
  );

  const [movements, setMovements] = useState<MovementElem[] | []>();

  useEffect(() => {
    if (!error && data) {
      setMovements(data.data.movements);
    }
  }, [data]);

  return {
    movements,
    isLoading,
    error,
  };
};
