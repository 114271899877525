import { Box, Drawer, List, Toolbar } from '@mui/material';
import { LogoIcon } from '@images/.';
import { useResponsive } from '@hooks/useMedia';
import { NAV_WIDTH } from '../shared/constants';
import { MainListItems } from './Items';

type Props = {
  open: boolean;
  handleDrawerToggle: () => void;
};

const TESTIDS = {
  wrapper: 'drawer-wrapper',
  content: 'drawer-content',
  mobileDrawer: 'mobile-drawer',
  desktopDrawer: 'desktop-drawer',
};

export const MainDrawer = ({ open, handleDrawerToggle }: Props) => {
  const isDesktop = useResponsive('up', 'lg');

  const DrawerContent = (
    <>
      <Toolbar
        data-testid={TESTIDS.content}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: [1],
        }}
      >
        <LogoIcon height={150} />
      </Toolbar>
      <List component="nav">
        <MainListItems />
      </List>
    </>
  );

  return (
    <Box
      data-testid={TESTIDS.wrapper}
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          data-testid={TESTIDS.desktopDrawer}
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              //borderRightStyle: 'dashed',
            },
          }}
        >
          {DrawerContent}
        </Drawer>
      ) : (
        <Drawer
          data-testid={TESTIDS.mobileDrawer}
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {DrawerContent}
        </Drawer>
      )}
    </Box>
  );
};

MainDrawer.TESTIDS = TESTIDS;
