import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface Token {
  token?: string;
  expirationDate?: string;
}

interface AuthStore {
  isLogin: boolean;
  token?: Token;
  setIsLogin: (isLogin: boolean) => void;
  setGoogleToken: (googleToken?: string) => void;
  setExpirationDate: (expirationDate?: string) => void;
}

export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      isLogin: false,
      token: undefined,
      setIsLogin: (isLogin: boolean) => set({ isLogin }),
      setGoogleToken: (googleToken?: string) =>
        set((state) => ({
          token: { ...state.token, token: googleToken },
        })),
      setExpirationDate: (expirationDate?: string) =>
        set((state) => ({
          token: { ...state.token, expirationDate: expirationDate },
        })),
    }),
    {
      name: 'useAuthStore',
    },
  ),
);
