import { EquipmentElem } from '../../../../package/api/src/thunderwod';
import {
  barbell as BarbellIcon,
  bench as BenchIcon,
  bike as BikeIcon,
  box as BoxIcon,
  chair as ChairIcon,
  dumbbell as DumbbellIcon,
  kettlebell as KettlebellIcon,
  medicineball as MedicineBall,
  pullups as PullUpsIcon,
  rack as RackIcon,
  rings as RingsIcon,
  rope as RopeIcon,
  rowing as RowingIcon,
  rug as RugIcon,
  slamball as SlamBall,
} from '@images/.';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useResponsive } from '@hooks/useMedia';

interface Props {
  equipments: EquipmentElem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: GeneratorInputs) => void;
}

const TESTIDS = {
  wrapper: 'wrapper-workoutGeneratorForm',
  form: {
    wrapper: {
      workoutType: 'wrapper-workoutType',
      workoutEquipment: 'wrapper-workoutEquipment',
    },
    label: {
      workoutType: 'label-workoutType',
      workoutEquipment: 'label-workoutEquipment',
    },
    equipment: (name: string) => `equipment-${name}`,
    submitBtn: 'submit-btn',
  },
};

const Icon = (name: string) => {
  const upperCaseName = name.toUpperCase();

  if (upperCaseName === 'BENCH') return BenchIcon;
  if (upperCaseName === 'RACK') return RackIcon;
  if (upperCaseName === 'BARBELL') return BarbellIcon;
  if (upperCaseName === 'RINGS') return RingsIcon;
  if (upperCaseName === 'KETTLEBELL') return KettlebellIcon;
  if (upperCaseName === 'ROPE') return RopeIcon;
  if (upperCaseName === 'BOX') return BoxIcon;
  if (upperCaseName === 'DUMBBELL') return DumbbellIcon;
  if (upperCaseName === 'PULL UP BAR') return PullUpsIcon;
  if (upperCaseName === 'BODY') return RugIcon;
  if (upperCaseName === 'MEDICINE BALL') return MedicineBall;
  if (upperCaseName === 'SLAM BALL') return SlamBall;
  if (upperCaseName === 'ROW') return RowingIcon;
  if (upperCaseName === 'AIR BIKE') return BikeIcon;
  if (upperCaseName === 'CHAIR') return ChairIcon;
  return name;
};

const EquipmentGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: theme.space.s,
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  '& .MuiToggleButtonGroup-grouped': {
    border: '1px solid rgba(0, 0, 0, 0.12) !important',
    borderRadius: 0,
  },
  '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
    marginLeft: 0,
  },
  '& .MuiToggleButton-root': {
    margin: '7px',
  },
}));

export type GeneratorInputs = {
  workoutType?: string;
  workoutEquipment: string[];
};

export const WorkoutGeneratorForm = ({ equipments, onSubmit }: Props) => {
  const isDesktop = useResponsive('up', 'lg');

  const { space } = useTheme();

  const [selectedEquipment, setSelectedEquipment] = useState<string[]>([]);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<GeneratorInputs>({
    defaultValues: {
      workoutEquipment: [],
      workoutType: undefined,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth data-testid={TESTIDS.wrapper}>
        <Box mb={2} data-testid={TESTIDS.form.wrapper.workoutType}>
          <FormLabel data-testid={TESTIDS.form.label.workoutType}>
            Workout Type
          </FormLabel>
          <Controller
            name="workoutType"
            control={control}
            rules={{
              required: 'First select a workout type!',
            }}
            render={({ field }) => {
              return (
                <ToggleButtonGroup
                  {...field}
                  size="large"
                  sx={{ marginTop: space.s }}
                  fullWidth
                  color="primary"
                  exclusive
                  orientation={isDesktop ? 'horizontal' : 'vertical'}
                >
                  <ToggleButton value="amrap">AMRAP</ToggleButton>
                  <ToggleButton value="emom">EMOM</ToggleButton>
                  <ToggleButton value="tabata">TABATA</ToggleButton>
                  <ToggleButton value="rft">RFT</ToggleButton>
                </ToggleButtonGroup>
              );
            }}
          />

          {errors.workoutType && (
            <FormHelperText error required>
              {(errors.workoutType.message as string) || ''}
            </FormHelperText>
          )}
        </Box>

        <Box mb={2} data-testid={TESTIDS.form.wrapper.workoutEquipment}>
          <FormLabel
            sx={{ width: 1 }}
            data-testid={TESTIDS.form.label.workoutEquipment}
          >
            Equipment
          </FormLabel>

          <Controller
            name="workoutEquipment"
            control={control}
            render={({ field }) => {
              return (
                <EquipmentGroup
                  {...field}
                  size="large"
                  value={selectedEquipment}
                  onChange={(_, value: string[]) => {
                    setSelectedEquipment(value);
                    setValue(field.name, value);
                  }}
                  exclusive={false}
                  orientation="horizontal"
                >
                  {equipments?.map((equip) => {
                    if (equip.value.toUpperCase() === 'CHAIR') return null;

                    return (
                      <ToggleButton
                        key={equip.uuid}
                        value={`${equip.uuid}_${equip.value}`}
                        data-testid={TESTIDS.form.equipment(equip.value)}
                        color="primary"
                      >
                        <Box sx={{ width: '120px' }}>
                          <img
                            src={Icon(equip.value)}
                            style={{
                              width: '55px',
                            }}
                          />
                          <Typography>{equip.value}</Typography>
                        </Box>
                      </ToggleButton>
                    );
                  })}
                </EquipmentGroup>
              );
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            data-testid={TESTIDS.form.submitBtn}
          >
            Submit
          </Button>
        </Box>
      </FormControl>
    </form>
  );
};

WorkoutGeneratorForm.TESTIDS = TESTIDS;
