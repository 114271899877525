import { useApproveWorkout } from '@hooks/useApproveWorkout';
import { useLogin } from '@hooks/useLogin';
import { useMutateUserDetails } from '@hooks/useMutateUserDetails';
import { useWorkout } from '@hooks/useWorkout';
import { useWorkouts } from '@hooks/useWorkouts';
import { Avatar, Box, Grid, Paper, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AccountForm, Inputs } from '../components/Account/Form';
import { FormAccountTransformer } from '../components/Account/Form/transformer';
import { Catalogue } from '../components/Workout/Catalogue';
import { Loading, MainContent } from '../layout/components';

const TESTIDS = {
  wrapper: 'wrappper-settings-page',
  info: {
    form: { ...AccountForm.TESTIDS },
    picture: 'picture',
  },
  admin: {
    workoutListWrapper: 'workouts-list-wrapper',
    workoutsLoading: 'workouts-loading',
  },
};

export const AccountPage = () => {
  const theme = useTheme();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const { userDetails, googleToken, isAdmin } = useLogin();

  const { delete: deleteWorkout } = useWorkout(googleToken);
  const {
    workouts,
    pagination,
    isLoading: isWorkoutsLoading,
    refetch: workoutsRefresh,
  } = useWorkouts(currentPage, rowsPerPage, isAdmin, googleToken);
  const { isSuccess: isApproveSuccess, approve } =
    useApproveWorkout(googleToken);
  const { mutate: updateAccount } = useMutateUserDetails(googleToken);

  useEffect(() => {
    if (deleteWorkout.isSuccess) {
      workoutsRefresh();
    }
  }, [deleteWorkout.isSuccess]);

  useEffect(() => {
    if (isApproveSuccess) {
      workoutsRefresh();
    }
  }, [isApproveSuccess]);

  if (isWorkoutsLoading) {
    return (
      <MainContent>
        <Box data-testid={TESTIDS.admin.workoutsLoading}>
          <Loading size={200} color={theme.palette.secondary.main} />
        </Box>
      </MainContent>
    );
  }

  if (!userDetails) return null;

  const userDetailsForm = userDetails
    ? FormAccountTransformer.toForm(userDetails)
    : undefined;

  const handleOnSaveAccountDetails = (data: Inputs) => {
    updateAccount({
      instagram: !data.instagram ? undefined : data.instagram,
    });
  };

  return (
    <MainContent>
      <Grid
        data-testid={TESTIDS.wrapper}
        container
        justifyContent="center"
        spacing={2}
      >
        <Avatar
          data-testid={TESTIDS.info.picture}
          sx={{ width: 150, height: 150 }}
          alt={`${userDetails.firstName} ${userDetails.lastName}`}
          src={userDetails.picture}
        />
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: '10px' }}>
            <AccountForm
              defaultValues={userDetailsForm}
              onSave={handleOnSaveAccountDetails}
            />
          </Paper>
        </Grid>

        {isAdmin ? (
          <Grid
            item
            xs={12}
            md={12}
            mt={2}
            data-testid={TESTIDS.admin.workoutListWrapper}
          >
            <Catalogue
              workouts={workouts}
              currentPage={pagination.currentPage ?? 1}
              rowsPerPage={pagination.sizePerPage ?? 5}
              totalOfWorkouts={pagination.totalWorkouts ?? 0}
              onPageChange={(newPage: number) => setCurrentPage(newPage)}
              onRowsPerPageChange={(rowsPerPage: number) =>
                setRowsPerPage(rowsPerPage)
              }
              onDelete={(uuid?: string) => deleteWorkout.mutate(uuid ?? '')}
              onEdit={() => toast.error(`Operation not yet implemented`)}
              onApprove={(uuid: string) => approve(uuid)}
            />
          </Grid>
        ) : null}
      </Grid>
    </MainContent>
  );
};

AccountPage.TESTIDS = TESTIDS;
