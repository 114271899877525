import {
  Avatar,
  Box,
  CircularProgress,
  CircularProgressProps,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';

const TESTIDS = {
  wrapper: 'wrapper-message',
  assistantAvatar: 'assistantAvatar',
  assistantLoadingMessage: 'assistantLoadingMessage',
  userAvatar: 'userAvatar',
  message: 'message',
};

type Props = {
  orientation: 'left' | 'right';
  message: string;
  avatarImg?: string | React.ReactElement;
  typingEffect?: boolean;
};

//TODO: Review this code, this was copied from MUI
const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number },
) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export const Message = ({
  orientation,
  message,
  avatarImg,
  typingEffect = false,
}: Props) => {
  const isAssistantChat = orientation === 'left';

  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPercentage, setCurrentPercentage] = useState(0);

  useEffect(() => {
    if (typingEffect) {
      if (currentIndex < message.length) {
        const timeout = setTimeout(() => {
          setCurrentText((prevText) => prevText + message[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);

          //FIXME: This doesn't look right visually, please have a look
          setCurrentPercentage((prevPercentage) => {
            return Math.min((currentIndex / message.length) * 100, 100);
          });
        }, 1);

        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [currentIndex, message]);

  return (
    <ListItem
      data-testid={TESTIDS.wrapper}
      sx={{
        display: 'flex',
        justifyContent: isAssistantChat ? 'flex-start' : 'flex-end',
      }}
    >
      <Paper
        sx={{
          width: '100%',
          padding: 2,
          display: 'flex',
          justifyContent: isAssistantChat ? 'flex-start' : 'flex-end',
        }}
      >
        {isAssistantChat && message.length === currentIndex ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            padding={1}
            data-testid={TESTIDS.assistantAvatar}
          >
            <Avatar
              alt="ThunderWOD"
              sx={{ background: 'none', border: 1, color: 'grey' }}
            >
              {avatarImg}
            </Avatar>
          </Box>
        ) : null}
        {isAssistantChat && message.length !== currentIndex ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            p={1}
            data-testid={TESTIDS.assistantLoadingMessage}
          >
            <CircularProgressWithLabel value={currentPercentage} />
          </Box>
        ) : null}
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <ListItemText
                data-testid={TESTIDS.message}
                secondary={
                  <Markdown>{typingEffect ? currentText : message}</Markdown>
                }
              />
            </Grid>
          </Grid>
        </Box>
        {!isAssistantChat ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 1,
            }}
            data-testid={TESTIDS.userAvatar}
          >
            <Avatar alt="You" src={avatarImg as string}></Avatar>
          </Box>
        ) : null}
      </Paper>
    </ListItem>
  );
};

Message.TESTIDS = TESTIDS;
