import { useLogin } from '@hooks/useLogin';
import {
  AccountBalance as BillingPortalIcon,
  Construction as BuilderIcon,
  ContactSupport as ContactSupportIcon,
  Dashboard as DashboardIcon,
  ManageAccounts as MyAccountIcon,
  Policy as TermsConditionIcon,
  Assistant as WodAssistant,
  FitnessCenter as WodGeneratorIcon,
} from '@mui/icons-material';
import { Box, Link, Link as MuiLink, styled } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { followus as FollowUs } from '../../../assets';
import { Login } from '../../../components/Login';
import { Uri } from '../../../util/Uri';

const ListItem = styled(ListItemIcon)(() => ({
  justifyContent: 'center',
}));

const TESTIDS = {
  list: {
    homePage: {
      link: 'home-page-link',
      title: 'home-page-title',
      icon: 'home-page-icon',
    },
    ai: {
      link: 'assistant-page-link',
      title: 'assistant-page-title',
      icon: 'assistant-page-icon',
    },
    myAccountPage: {
      link: 'myAccount-page-link',
      title: 'myAccount-page-title',
      icon: 'myAccount-page-icon',
    },
    workoutFilterPage: {
      link: 'workoutFilter-page-link',
      title: 'workoutFilter-page-title',
      icon: 'workoutFilter-page-icon',
    },
    workoutBuilderPage: {
      link: 'workoutBuilder-page-link',
      title: 'workoutBuilder-page-title',
      icon: 'workoutBuilder-page-icon',
    },
    billingPortalPage: {
      link: 'billingPortal-page-link',
      title: 'billingPortal-page-title',
      icon: 'billingPortal-page-icon',
    },
    termsConditionsPage: {
      link: 'termsConditions-page-link',
      title: 'termsConditions-page-title',
      icon: 'termsConditions-page-icon',
    },
    contactSupportPage: {
      link: 'supportPage-page-link',
      title: 'supportPage-page-title',
      icon: 'supportPage-page-icon',
    },
  },
};

const links = [
  {
    dataTestIds: TESTIDS.list.homePage,
    to: '/',
    label: 'Thunderwod',
    icon: <DashboardIcon data-testid={TESTIDS.list.homePage.icon} />,
    protected: false,
    target: '_self',
  },
  {
    dataTestIds: TESTIDS.list.ai,
    to: '/workouts/assistant',
    label: 'WOD Assistent',
    icon: <WodAssistant data-testid={TESTIDS.list.ai.icon} />,
    protected: false,
    target: '_self',
  },
  {
    dataTestIds: TESTIDS.list.workoutFilterPage,
    to: '/workout/filter',
    label: 'WOD Generator',
    icon: (
      <WodGeneratorIcon data-testid={TESTIDS.list.workoutFilterPage.icon} />
    ),
    protected: false,
    target: '_self',
  },
  {
    dataTestIds: TESTIDS.list.workoutBuilderPage,
    to: '/workout/builder',
    label: 'Builder',
    icon: <BuilderIcon data-testid={TESTIDS.list.workoutBuilderPage.icon} />,
    protected: true,
    target: '_self',
  },
  {
    dataTestIds: TESTIDS.list.myAccountPage,
    to: '/account',
    label: 'My Account',
    icon: <MyAccountIcon data-testid={TESTIDS.list.myAccountPage.icon} />,
    protected: true,
    target: '_self',
  },
  {
    dataTestIds: TESTIDS.list.billingPortalPage,
    to: Uri.customerStripeBillingPortal,
    label: 'Billing Portal',
    icon: (
      <BillingPortalIcon data-testid={TESTIDS.list.billingPortalPage.icon} />
    ),
    protected: true,
    target: '_blank',
  },
  {
    dataTestIds: TESTIDS.list.termsConditionsPage,
    to: '/terms-and-conditions',
    label: 'Terms & Conditions',
    icon: (
      <TermsConditionIcon data-testid={TESTIDS.list.termsConditionsPage.icon} />
    ),
    protected: false,
    target: '_self',
  },
];

export const MainListItems = () => {
  const { isLogin } = useLogin();

  return (
    <React.Fragment>
      {links.map((link) => {
        if (!link.protected || (link.protected && isLogin)) {
          return (
            <Link
              key={link.label}
              data-testid={link.dataTestIds.link}
              style={{
                textDecoration: 'none',
              }}
              href={link.to}
              target={link.target}
            >
              <ListItemButton>
                <ListItem>{link.icon}</ListItem>
                <ListItemText
                  data-testid={link.dataTestIds.title}
                  secondary={link.label}
                />
              </ListItemButton>
            </Link>
          );
        }
      })}

      <MuiLink
        style={{
          textDecoration: 'none',
        }}
        href="mailto:nbento.neves@gmail.com"
        data-testid={TESTIDS.list.contactSupportPage.link}
      >
        <ListItemButton>
          <ListItem>
            <ContactSupportIcon
              data-testid={TESTIDS.list.contactSupportPage.icon}
            />
          </ListItem>
          <ListItemText
            data-testid={TESTIDS.list.contactSupportPage.title}
            secondary="Contact Support"
          />
        </ListItemButton>
      </MuiLink>

      <Box
        m={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Login />
      </Box>
      <Box
        mt={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Link href={`https://www.instagram.com/thunderwod.web`} target="_blank">
          <img
            src={FollowUs}
            alt="follow-us-instagram"
            style={{
              width: '150px',
              height: '100%',
            }}
          />
        </Link>
      </Box>
    </React.Fragment>
  );
};

MainListItems.TESTIDS = TESTIDS;
