import { Typography } from '@mui/material';
import { useMemo } from 'react';

type Props = {
  name: string;
  numberOfReps: number;
  weightMan?: number;
  weightWoman?: number;
  weightScale?: 'kg' | 'lbs';
};

const TESTIDS = {
  wrapper: 'wrapper-exercise-weight',
};

export const ExerciseWeight = ({
  name,
  numberOfReps,
  weightMan,
  weightWoman,
  weightScale = 'kg',
}: Props) => {
  const buildText = useMemo(() => {
    switch (weightScale) {
      case 'kg':
        return `${numberOfReps || ''} ${name} @ ${weightMan}/${weightWoman} kg`;
      case 'lbs':
        return `${
          numberOfReps || ''
        } ${name} @ ${weightMan}/${weightWoman} lbs`;
      default:
        return `${numberOfReps || ''} ${name}`;
    }
  }, [name, numberOfReps, weightMan, weightWoman, weightScale]);

  return (
    <Typography data-testid={TESTIDS.wrapper} component="div" variant="body2">
      {buildText}
    </Typography>
  );
};

ExerciseWeight.TESTIDS = TESTIDS;
