import { Grid, useTheme } from '@mui/material';
import { workout1, workout2, workout3, workout5, hyrox } from '../assets';
import { MainContent, SolidCard } from '../layout/components';

const TESTIDS = {
  wrapper: 'wrappper-home-page',
  items: {
    emom: 'emom',
    amrap: 'amrap',
    tabata: 'tabata',
    time: 'time',
    hyrox: 'hyrox',
  },
};

export const HomePage = () => {
  const theme = useTheme();

  return (
    <MainContent>
      <Grid
        data-testid={TESTIDS.wrapper}
        container
        justifyContent="center"
        spacing={2}
      >
        <Grid item data-testid={TESTIDS.items.emom}>
          <SolidCard
            color={theme.workout.color.emom}
            title="EMOM"
            image={workout1}
            link="workout/EMOM"
          />
        </Grid>
        <Grid item data-testid={TESTIDS.items.amrap}>
          <SolidCard
            color={theme.workout.color.amrap}
            title="AMRAP"
            image={workout2}
            link="workout/AMRAP"
          />
        </Grid>
        <Grid item data-testid={TESTIDS.items.tabata}>
          <SolidCard
            color={theme.workout.color.tabata}
            title="TABATA"
            image={workout3}
            link="workout/TABATA"
          />
        </Grid>
        <Grid item data-testid={TESTIDS.items.time}>
          <SolidCard
            color={theme.workout.color.fortime}
            title="FOR TIME"
            image={workout5}
            link="workout/RFT"
          />
        </Grid>
        <Grid item data-testid={TESTIDS.items.hyrox}>
          <SolidCard
            useNewBadge
            color={theme.workout.color.hyrox}
            title="HYROX"
            image={hyrox}
            link="workout/HYROX"
          />
        </Grid>
      </Grid>
    </MainContent>
  );
};

HomePage.TESTIDS = TESTIDS;
