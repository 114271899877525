import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { MovementField } from '..';
import { WorkoutMovement } from '../../../../types';

interface Props {
  workoutMovements: WorkoutMovement[];
  onDelete: (index: number) => void;
  onChangeMovementField: (
    index: number,
    field: MovementField,
    newValue: string,
  ) => void;
  onSwap: (index: number, swap: 'up' | 'down') => void;
}

const TESTIDS = {
  wrapper: 'wrapper-movements-table',
  name: (rowNum: number) => rowNum + '-movements-name',
  unit: (rowNum: number) => rowNum + '-movements-unit',
  reps: (rowNum: number) => rowNum + '-movements-reps',
  duration: (rowNum: number) => rowNum + '-movements-duration',
  distance: (rowNum: number) => rowNum + '-movements-distance',
  loadMan: (rowNum: number) => rowNum + '-movements-loadMan',
  loadWoman: (rowNum: number) => rowNum + '-movements-loadWoman',
  swapUp: (rowNum: number) => rowNum + '-movements-swapUp',
  swapDown: (rowNum: number) => rowNum + '-movements-swapDown',
  delete: (rowNum: number) => rowNum + '-movements-delete',
};

export const MovementsTable = ({
  workoutMovements,
  onChangeMovementField,
  onDelete,
  onSwap,
}: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" data-testid={TESTIDS.wrapper}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="center">Unit</TableCell>
            <TableCell align="center">Reps</TableCell>
            <TableCell align="center">Duration</TableCell>
            <TableCell align="center">Distance</TableCell>
            <TableCell align="center">Load Man</TableCell>
            <TableCell align="center">Load Woman</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workoutMovements.map((movement, index) => {
            const arrowUpEnable = index > 0;
            const arrowDownEnable =
              (index > 0 || index < workoutMovements.length - 1) &&
              index !== workoutMovements.length - 1;

            return (
              <TableRow
                key={`${movement.uuid}_${index}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  data-testid={TESTIDS.name(index)}
                >
                  {movement.name}
                </TableCell>
                <TableCell align="right" data-testid={TESTIDS.unit(index)}>
                  {movement.unit}
                </TableCell>
                <TableCell align="right">
                  <TextField
                    variant="standard"
                    value={movement.numOfReps ?? 0}
                    type="number"
                    inputProps={{ 'data-testid': TESTIDS.reps(index) }}
                    onChange={(event) =>
                      onChangeMovementField(
                        index,
                        MovementField.NUM_OF_REPS,
                        event.target.value,
                      )
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    variant="standard"
                    value={movement.duration ?? 0}
                    type="number"
                    inputProps={{ 'data-testid': TESTIDS.duration(index) }}
                    onChange={(event) =>
                      onChangeMovementField(
                        index,
                        MovementField.DURATION,
                        event.target.value,
                      )
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    variant="standard"
                    value={movement.distance ?? 0}
                    type="number"
                    inputProps={{ 'data-testid': TESTIDS.distance(index) }}
                    onChange={(event) =>
                      onChangeMovementField(
                        index,
                        MovementField.DISTANCE,
                        event.target.value,
                      )
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    variant="standard"
                    value={movement.loadMan ?? 0}
                    type="number"
                    inputProps={{ 'data-testid': TESTIDS.loadMan(index) }}
                    onChange={(event) =>
                      onChangeMovementField(
                        index,
                        MovementField.LOAD_MAN,
                        event.target.value,
                      )
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    variant="standard"
                    value={movement.loadWoman ?? 0}
                    type="number"
                    inputProps={{ 'data-testid': TESTIDS.loadWoman(index) }}
                    onChange={(event) =>
                      onChangeMovementField(
                        index,
                        MovementField.LOAD_WOMAN,
                        event.target.value,
                      )
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  {arrowUpEnable ? (
                    <IconButton
                      aria-label="move-up"
                      size="large"
                      onClick={() => onSwap(index, 'up')}
                      data-testid={TESTIDS.swapUp(index)}
                    >
                      <ArrowUpIcon fontSize="inherit" />
                    </IconButton>
                  ) : null}
                  {arrowDownEnable ? (
                    <IconButton
                      aria-label="move-down"
                      size="large"
                      onClick={() => onSwap(index, 'down')}
                      data-testid={TESTIDS.swapDown(index)}
                    >
                      <ArrowDownIcon fontSize="inherit" />
                    </IconButton>
                  ) : null}
                  <IconButton
                    aria-label="delete"
                    size="large"
                    onClick={() => onDelete(index)}
                    data-testid={TESTIDS.delete(index)}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

MovementsTable.TESTIDS = TESTIDS;
