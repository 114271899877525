import { ApiContext } from '../package/api/context';
import { useContext } from 'react';
import { useMutation } from 'react-query';

export const useMutateWorkout = (id?: string) => {
  const { endpoints } = useContext(ApiContext);

  const { data, isLoading, error, mutate } = useMutation(
    ['workoutId', id],
    () =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      endpoints.thunderwod.getWorkout(id!),
  );

  return { workout: data?.data.workout, isLoading, error, fetch: mutate };
};
