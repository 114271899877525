import { WorkoutType } from '../types';

export type SeoInfoWorkoutType = {
  title: string;
  description: string;
  tags: string;
};

const getSeoInfoBasedWorkoutType = (
  workoutType: WorkoutType,
): SeoInfoWorkoutType => {
  if (workoutType === 'AMRAP') {
    return {
      title: 'ThunderWOD - AMRAP Workout!',
      description:
        'A type of exercise that involves performing as many repetitions or rounds of an exercise as possible within a set time limit.',
      tags: 'crossfit, wod, amrap workout, amrap, cross-training, many rounds as possible',
    };
  }

  if (workoutType === 'EMOM') {
    return {
      title: 'ThunderWOD - EMOM Workout!',
      description:
        'A type of interval training that involves short, intense bursts of exercise with built-in rest periods.',
      tags: 'crossfit, wod, emom workout, emom, cross-training, every minute on the minute',
    };
  }

  if (workoutType === 'RFT') {
    return {
      title: 'ThunderWOD - RFT Workout!',
      description:
        'A type of workout that involves completing a set of exercises as quickly as possible',
      tags: 'crossfit, wod, rft workout, rft, cross-training, rounds for time',
    };
  }

  if (workoutType === 'TABATA') {
    return {
      title: 'ThunderWOD - Tabata Workout!',
      description:
        'A style of high-intensity interval training (HIIT) that involves 20 seconds of exercise at your maximum effort, followed by 10 seconds of rest, for a certain number of rounds',
      tags: 'crossfit, wod, tabata workout, cross-training, high-intensity internal training, hiit',
    };
  }

  if (workoutType === 'HYROX') {
    return {
      title: 'ThunderWOD - Hyrox Workout!',
      description:
        'A global fitness race that combines running and functional fitness exercises',
      tags: 'crossfit, hyrox, hyrox workout, cross-training, hyrox race, wall balls, running',
    };
  }

  return {
    title: 'ThunderWOD - Free workout to burn some calories!',
    description:
      'This is a free workout that can help you to improve your performance or burn some calories. Get unlimited workout ideas, for free!',
    tags: 'crossfit, wod, levels fitness app, cross training, cross-training, free weight workout routines, best free gym workout apps',
  };
};

export const SeoUtils = {
  getSeoInfoBasedWorkoutType,
};
