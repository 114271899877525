import { useContext, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { shallow } from 'zustand/shallow';
import { ApiContext } from '../package/api/context';
import { useAuthStore } from '../storage';
import { Headers } from '../util/Headers';

export const useLogin = () => {
  const { endpoints } = useContext(ApiContext);

  const isLogin = useAuthStore((state) => state.isLogin);
  const googleToken = useAuthStore((state) => state.token?.token);

  const { data, isLoading, error } = useQuery(
    ['googleToken', googleToken],
    () =>
      endpoints.thunderwod.getUserDetails(
        Headers.buildAuthRequestHeaders(googleToken ?? ''),
      ),
    { enabled: !!googleToken && !isLogin },
  );

  const { mutate: getGoogleUrl } = useMutation(
    ['getGoogleUrl'],
    () => endpoints.thunderwod.getGoogleAuthUrl(),
    {
      onSuccess: ({ data }) => {
        window.location.href = data;
      },
    },
  );

  const { mutate: getGoogleToken, data: googleTokenData } = useMutation(
    ['getGoogleToken'],
    (googleCode: string) => endpoints.thunderwod.getGoogleAuthToken(googleCode),
  );

  const isMember = useMemo(() => {
    return !!data?.data?.role?.find(
      (role) => role === 'MEMBER' || role === 'ADMIN',
    );
  }, [data]);

  const isAdmin = useMemo(() => {
    return !!data?.data?.role?.find((role) => role === 'ADMIN');
  }, [data]);

  const { setIsLogin, setGoogleToken, setExpirationDate } = useAuthStore(
    (state) => ({
      setIsLogin: state.setIsLogin,
      setGoogleToken: state.setGoogleToken,
      setExpirationDate: state.setExpirationDate,
    }),
    shallow,
  );

  const googleLogout = () => {
    setIsLogin(false);
    setGoogleToken(undefined);
  };

  useEffect(() => {
    if (data && !error) {
      setIsLogin(true);
      setExpirationDate(data.data.expire);
    } else {
      setIsLogin(false);
    }
  }, [data]);

  useEffect(() => {
    if (googleTokenData) {
      setGoogleToken(googleTokenData.data);
    }
  }, [googleTokenData]);

  return {
    googleLogout,
    getGoogleUrl,
    getGoogleToken,
    isLogin,
    googleToken,
    userDetails: data?.data,
    isMember,
    isAdmin,
    isLoading,
  };
};
