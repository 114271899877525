import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLogin } from '../../hooks/useLogin';
import { GoogleSignIn } from './Google';

const TESTIDS = {
  loginBtn: 'loginBtn',
  logoutBtn: 'logoutBtn',
};

export const Login = () => {
  const { googleLogout, getGoogleUrl, getGoogleToken, isLogin } = useLogin();

  const [tokenCode, setTokenCode] = useState<string>();

  useEffect(() => {
    const accessCodeRegex = /code=([^&]+)/;
    const isMatch = window.location.href.match(accessCodeRegex);

    if (isMatch && !isLogin) {
      const accessToken = isMatch[1];
      setTokenCode(accessToken);
    }
  }, []);

  useEffect(() => {
    if (tokenCode) {
      getGoogleToken(tokenCode);
    }
  }, [tokenCode]);

  return (
    <React.Fragment>
      {!isLogin && (
        <Box data-testid={TESTIDS.loginBtn}>
          <GoogleSignIn onClick={() => getGoogleUrl()} />
        </Box>
      )}

      {isLogin && (
        <Button
          data-testid={TESTIDS.logoutBtn}
          onClick={() => {
            googleLogout();
          }}
        >
          Logout
        </Button>
      )}
    </React.Fragment>
  );
};

Login.TESTIDS = TESTIDS;
